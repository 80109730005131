/* Regresa todos los datos del usuario con el id enviado de la tabla 'orglogos' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getOrg_logosById = async( id ) => {
    const promiseArr = [
        simejuvApi.get(`/org_logos/${ id }`)
    ]

    const [ orglogos ] = await Promise.all( promiseArr )

    return orglogos.data
}

export default getOrg_logosById