<template>
    <h4 class="mt-0">Actualizar textos e imagenes del módulo Acerca de</h4>
    <hr>
    <div class="row mb-5">
        <div class="col-md-12">
            <form>

                <div class="form-group row">
                    <label class="col-lg-4 col-md-6 col-sm-12 col-form-label" for="ididioma">Idioma:</label>
                    <div class="col-lg-4 col-md-6 col-sm-12">
                        <select class="form-control" id="ididioma" name="ididioma" v-model="form.ididioma">
                            <option v-for="e_d in idiomasArr" :key="e_d.id" :value="e_d.id">{{ e_d.nom_corto_idioma }} {{ e_d.nom_idioma }}</option>
                        </select>
                    </div>
                </div>


                <div class="form-group row">
                    <label class="col-md-4" to="acercade_titulo">Titulo Acerca de:</label>
                    <div class="col-md-8">
                        <ckeditor :editor="editor" v-model="form.acercade_titulo" :config="editorConfig" id="acercade_titulo" @input="conteo_real(form.acercade_titulo, 'acercade_titulo')"></ckeditor>
                        <div id="acercade_titulo_count" class="noChars"></div>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-md-4" to="acercade_descripcion">Contenido superior izquierdo:</label>
                    <div class="col-md-8">
                        <ckeditor :editor="editor" v-model="form.acercade_descripcion" :config="editorConfig" id="acercade_descripcion" @input="conteo_real(form.acercade_descripcion, 'acercade_descripcion')"></ckeditor>
                        <div id="acercade_descripcion_count" class="noChars"></div>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-md-4" to="acercade_contenido">Contenido superior derecho:</label>
                    <div class="col-md-8">
                        <input type="hidden" id="idBienvenida" name="idBienvenida" v-model="form.id" />
                        <ckeditor :editor="editor" v-model="form.acercade_contenido" :config="editorConfig" id="acercade_contenido" @input="conteo_real(form.acercade_contenido, 'acercade_contenido')"></ckeditor>
                        <div id="acercade_contenido_count" class="noChars"></div>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-md-4" to="beneficios_titulo">Título Beneficios:</label>
                    <div class="col-md-8">
                        <input type="hidden" id="idBienvenida" name="idBienvenida" v-model="form.id" />
                        <ckeditor :editor="editor" v-model="form.beneficios_titulo" :config="editorConfig" id="beneficios_titulo" @input="conteo_real(form.beneficios_titulo, 'beneficios_titulo')"></ckeditor>
                        <div id="beneficios_titulo_count" class="noChars"></div>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-md-4" to="bt1_titulo">Parámetros del Beneficio 1</label>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="bt1_titulo">Título:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" id="bt1_titulo" name="bt1_titulo" placeholder="Título" v-model="form.bt1_titulo" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="bt1_desc">Descripción:</label>
                    <div class="col-md-8">
                        <ckeditor :editor="editor" v-model="form.bt1_desc" :config="editorConfig" id="bt1_desc" @input="conteo_real(form.bt1_desc, 'bt1_desc')"></ckeditor>
                        <div id="bt1_desc_count" class="noChars"></div>
                    </div>
                </div>


                <div class="form-group row">
                    <label class="col-md-4" to="bt2_titulo">Parámetros del Beneficio 2</label>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="bt2_titulo">Título:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" id="bt2_titulo" name="bt2_titulo" placeholder="Título" v-model="form.bt2_titulo" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="bt2_desc">Descripción:</label>
                    <div class="col-md-8">
                        <ckeditor :editor="editor" v-model="form.bt2_desc" :config="editorConfig" id="bt2_desc" @input="conteo_real(form.bt2_desc, 'bt2_desc')"></ckeditor>
                        <div id="bt2_desc_count" class="noChars"></div>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-md-4" to="bt3_titulo">Parámetros del Beneficio 3</label>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="bt3_titulo">Título:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" id="bt3_titulo" name="bt3_titulo" placeholder="Título" v-model="form.bt3_titulo" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="bt3_desc">Descripción:</label>
                    <div class="col-md-8">
                        <ckeditor :editor="editor" v-model="form.bt3_desc" :config="editorConfig" id="bt3_desc" @input="conteo_real(form.bt3_desc, 'bt3_desc')"></ckeditor>
                        <div id="bt3_desc_count" class="noChars"></div>
                    </div>
                </div>


                <div class="form-group row">
                    <label class="col-md-4" to="bt4_titulo">Parámetros del Beneficio 4</label>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="bt4_titulo">Título:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" id="bt4_titulo" name="bt4_titulo" placeholder="Título" v-model="form.bt4_titulo" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="bt4_desc">Descripción:</label>
                    <div class="col-md-8">
                        <ckeditor :editor="editor" v-model="form.bt4_desc" :config="editorConfig" id="bt4_desc" @input="conteo_real(form.bt4_desc, 'bt4_desc')"></ckeditor>
                        <div id="bt4_desc_count" class="noChars"></div>
                    </div>
                </div>



                <div class="form-group row">
                    <label class="col-md-4" to="limitaciones_contenido">Contenido de Limitaciones:</label>
                    <div class="col-md-8">
                        <input type="hidden" id="idBienvenida" name="idBienvenida" v-model="form.id" />
                        <ckeditor :editor="editor" v-model="form.limitaciones_contenido" :config="editorConfig" id="limitaciones_contenido" @input="conteo_real(form.limitaciones_contenido, 'limitaciones_contenido')"></ckeditor>
                        <div id="limitaciones_contenido_count" class="noChars"></div>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-md-4" to="contenido_organizacioones">Contenido de Organizaciones participantes:</label>
                    <div class="col-md-8">
                        <input type="hidden" id="idBienvenida" name="idBienvenida" v-model="form.id" />
                        <ckeditor :editor="editor" v-model="form.contenido_organizacioones" :config="editorConfig" id="contenido_organizacioones" @input="conteo_real(form.contenido_organizacioones, 'contenido_organizacioones')"></ckeditor>
                        <div id="contenido_organizacioones_count" class="noChars"></div>
                    </div>
                </div>


                <div class="form-group row">
                    <div class="col-md-6 text-left">
                        <input type="button" class="btn btn-default btn-sm active" id="regresar" name="regresar" value="Regresar" @click="goBack" />
                    </div>
                    <div class="col-md-6 text-right">
                        <input type="button" class="btn btn-primary btn-sm active" id="guardar" name="guardar" value="Guardar" @click="updateBienvenida" />
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import getAcercadeById from '@/helpers/getAcercadeById'
import updateData from '@/helpers/updateData'
import getCatsNc from '@/helpers/getCatsNc'

import charsService from '@/services/CharsService'
//import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

import { ClassicEditor, Bold, Essentials, Italic, Mention, Paragraph, Undo, Font, FontColor, Heading, FontFamily, Indent, IndentBlock, BlockQuote, Link, List} from 'ckeditor5';
/* La opción List habilita:  'bulletedList', 'numberedList'  */
import 'ckeditor5/ckeditor5.css';


export default {
    data() {
        return {
            editor: ClassicEditor,
            editorData: '',
            editorConfig: {
            plugins: [ Bold, Essentials, Italic, Mention, Paragraph, Undo, Font, FontColor, Heading, FontFamily, Indent, IndentBlock, BlockQuote, Link, List ],
   //           toolbar: [ 'heading', 'undo', 'redo', 'bulletedList', 'numberedList', 'fontColor', 'backgroundColor', 'bold', 'italic','fontSize', 'fontFamily'],
              
              language: 'es',
              wordCount: {
                    showParagraphs: false,
                    showWordCount: true,
                    showCharCount: true,
                    countSpacesAsChars: false,
                    countHTML: false,
                    maxWordCount: -1,
                    maxCharCount: 4000
              },
              fontFamily: {
                  options: [
                      'default',
                      'Helvetica',
                      'Arial',
                      'sans-serif',
                      'Courier New',
                      'Courier',
                      'monospace',
                      'Tahoma',
                      'Verdana'
                  ]
              },
              heading: {
                  options: [
                      { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                      { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                      { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                      { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                      { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                      { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                      { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' }
                  ]
              },
              fontColor: {
                  colorPicker: {
                      // Use 'hex' format for output instead of 'hsl'.
                      format: 'hex',
                      color: ['#30156B', '#542EA5','#7B62D9','#B995FF','#AD00E8','#78787A','#0D0D0D']
                  }
              },
              toolbar: {
                items: [
                  'heading',
                  '|', 'undo', 'redo',
                  '|', 'bold', 'italic',
                  '|', 'fontFamily', 'fontColor', 'backgroundColor', 'fontSize', 
                  '|', 'link', 'uploadImage', 'insertTable', 'blockQuote', 'mediaEmbed',
                  '|', 'bulletedList', 'numberedList', 'outdent', 'indent'
                  

                ]
              },
            },
            
            id: '',
            data: [],
            datos: [],
            form: {
                id: '',
                ididioma: '',   
                acercade_titulo: '',
                acercade_descripcion: '',
                acercade_contenido: '',
                beneficios_titulo: '',
                url_imagen_iz: '',
                bt1_titulo: '',
                bt1_desc: '',
                bt2_titulo: '',
                bt2_desc: '',
                bt3_titulo: '',
                bt3_desc: '',
                bt4_titulo: '',
                bt4_desc: '',
                limitaciones_contenido: '',
                contenido_organizacioones: '',
            },
            idiomasArr: [],
        }
    },
    methods: {
        async setData( id ) {
            const datos = await getAcercadeById( id )

            this.form.id = datos.id
            this.form.ididioma = datos.ididioma
            this.form.acercade_titulo = datos.acercade_titulo
            this.form.acercade_descripcion = datos.acercade_descripcion
            this.form.acercade_contenido = datos.acercade_contenido
            this.form.beneficios_titulo = datos.beneficios_titulo
            this.form.url_imagen_iz = datos.url_imagen_iz
            this.form.bt1_titulo = datos.bt1_titulo
            this.form.bt1_desc = datos.bt1_desc
            this.form.bt2_titulo = datos.bt2_titulo
            this.form.bt2_desc = datos.bt2_desc
            this.form.bt3_titulo = datos.bt3_titulo
            this.form.bt3_desc = datos.bt3_desc
            this.form.bt4_titulo = datos.bt4_titulo
            this.form.bt4_desc = datos.bt4_desc
            this.form.limitaciones_contenido = datos.limitaciones_contenido
            this.form.contenido_organizacioones = datos.contenido_organizacioones
        },

        async updateBienvenida() {
            const dataForm = {
                ididioma: this.form.ididioma,
                acercade_titulo: this.form.acercade_titulo,
                acercade_descripcion: this.form.acercade_descripcion,
                acercade_contenido: this.form.acercade_contenido,
                beneficios_titulo: this.form.beneficios_titulo,
                url_imagen_iz: this.form.url_imagen_iz,
                bt1_titulo: this.form.bt1_titulo,
                bt1_desc: this.form.bt1_desc,
                bt2_titulo: this.form.bt2_titulo,
                bt2_desc: this.form.bt2_desc,
                bt3_titulo: this.form.bt3_titulo,
                bt3_desc: this.form.bt3_desc,
                bt4_titulo: this.form.bt4_titulo,
                bt4_desc: this.form.bt4_desc,
                limitaciones_contenido: this.form.limitaciones_contenido,
                contenido_organizacioones: this.form.contenido_organizacioones
            };

            const jsonData = JSON.parse(JSON.stringify( dataForm ));

            const updAcc = await updateData('cat_acercade', this.form.id, jsonData)

            if ( updAcc[0].res == 1 ){
                this.$notify({
                    title: 'Correcto', text: 'Se actualizó correctamente la información', type: 'success', duration: 5000, speed: 1000
                });
            } else {
                this.$notify({
                    title: 'Error', text: 'Ocurrió un error. Verifica los datos', type: 'error'
                });
            }
        },
        async getIdiomas() {
            const cats = await getCatsNc('/cat_idioma?transform=1&columns=id,nom_corto_idioma,nom_idioma&filter=borrar,lt,1')
            return cats.cat_idioma

        },
        goBack() {
            this.$router.go(-1)
        },

        decodeHTMLEntities(text) {
            var textArea = document.createElement('textarea');
            textArea.innerHTML = text;
            return textArea.value;
        },

        async conteo_real( cont, id ) {
            const div = document.createElement('div');
            div.innerHTML = cont;
            const chars = div.textContent || div.innerText || '';
            const charsCount = this.decodeHTMLEntities(chars).length
            document.querySelector("#"+ id +"_count").innerHTML = "Número de caracteres: " + charsCount
        }

    },
    async mounted() {
        this.setData( charsService.decode( this.$route.params.id ))
        this.idiomasArr = await this.getIdiomas()
        window.scrollTo(0, 0)
    }
}
</script>

<style scoped>
    form {
        font-size: medium;
    }

    input, select {
        font-size: medium;
    }

    input[type=button] {
        font-size: 14px;
    }

    #bt1_desc {
        height: 500px;
    }

    .noChars {
        width: 100%;
        text-align: right;
        background-color: #FAFAFA;
        border: 1px solid #C4C4C4;
        border-top: none;
        color: #000;
        font-weight: 500;
        padding: 3px 10px;
    }
</style>