<template>
        <div class="panel-p" style="background-color:rgba(157, 112, 249, 0.7);">
            <div class="col-lg-12 col-md-6 col-sm-6 text-center" style="width: 100%;">
                <div class="row bg-light">
                    <div class="gradient"></div>
                    <div class="Imagen_responsive">    
                        <img  :src= "ImagenUrl" alt="Conoce tus derechos">
                    </div>    

                    <div class="carousel-caption  contenido_top">
                        <div class="col-md-5  card-contenedor-izq">
                           <div class="article-body" >
                           <!--<div class="article-body" style="box-shadow: 0px -23px 25px 0px rgba(0, 0, 0, 0.17) inset, 0px -36px 30px 0px rgba(0, 0, 0, 0.15) inset, 0px -79px 40px 0px rgba(0, 0, 0, 0.1) inset, 0px 2px 1px rgba(0, 0, 0, 0.06), 0px 4px 2px rgba(0, 0, 0, 0.09), 0px 8px 4px rgba(0, 0, 0, 0.09), 0px 16px 8px rgba(0, 0, 0, 0.09); background-color: #7c62d98d; " > -->
                           <!-- <div class="article-body" style="box-shadow: inset 2px 2px 10px rgba(255,255,255,.1), inset -5px -8px 8px rgba(0,0,0,.2); background-color: #7B62D9; " >   --> 
                                <span><p  class="text-left txt-contenido-responsivoN" v-html="contenido_principal"></p></span>
                                
                            </div>
                        </div>
                        <div class="col-md-1">
                        </div>
                        <div class="col-md-5 card-contenedor-der">
                            
                            <div  style="background-color:  rgb(173, 0, 232,.70); width: 100%; height: 340px; box-shadow: 0px 3px 5px rgb(61, 61, 61); border-radius: 0px; padding-bottom: 0px;">
                                <div class="article-body">
                                    <!-- Se pintan la TABLA -->
                                    <div class="card-body" style="margin-bottom: 0; overflow-x: auto; overflow-y: auto; max-height: 300px; width: 98%;">
                                        <!-- Se pinta la tabla de los avances -->
                                        <table class="table" >
                                            <tbody>
                                                <tr v-for="(h) in arrInformes" :key="h" class="tr_over" >
                                                    <td align="left"><a style="color: #ffffff;" :href="`${ ubApi }/download.php?tmpFileName=${ h.tmp_name }&fileName=${ h.file_name }`" target="_blank" title="Descargar"><i class="glyphicon glyphicon-download"></i></a></td>
                                                    <td><a style="color: #ffffff; text-decoration: none;" :href="`${ ubApi }/download.php?tmpFileName=${ h.tmp_name }&fileName=${ h.file_name }`" target="_blank" title="Descargar"> {{ h.publicacion_titulo }}</a></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <br>
                        </div>
                        <div class="col-md-1 card-contenedor-der">
                            
                        </div>
                    </div>
                        <div>
                            <div class="padre"><div class="hijo  gradient-creditos">Foto: ironwas/Shutterstock</div></div>
                        </div>            
                </div>
            </div>
        </div>
</template>

<script>
import getConoceDerechosById from '@/helpers/getConoceDerechosById'
//import getEventos_violencia_total_x_mun from '@/helpers/getEventos_violencia_total_x_mun'
import getCatDocumentos from '@/helpers/getCatDocumentos'
import { ubApi } from '@/api/baseUrl'
import { url } from '@/api/baseUrl'
import charsService from '@/services/CharsService';

export default {
    //url_imagen:'',
    data() {
        return { 
            ubApi: ubApi.value,
            url: url.value,
            //datosBienvenida: [],
            arrInformes: [],
            titulo_principal:'',
            contenido_principal:'',
            ImagenUrl:''

        }
    },
    methods: {
        async getConoceDerechos() {
            
            const datos = await getConoceDerechosById(  localStorage.getItem("Nidioma") )
            this.titulo_principal = datos.titulo_principal
            this.contenido_principal = datos.contenido_principal
            this.ImagenUrl = this.url + '/uploads/' + datos.imagen_url
            
            
            //const dataBienvenida = await getConoceDerechosById()
            //const arrShow = []
            //const titulo_principal = `${ dataBienvenida[0].titulo_principal }`
            //const contenido_principal = `${ dataBienvenida[0].contenido_principal }`
            //const ImagenUrl =  this.url + '/uploads/' + `${ dataBienvenida[0].imagen_url }`
        },
        async getAllEventosxMun() {
          const informesArr = await getCatDocumentos()
          const arrShow = []

          for (let i=0; i<informesArr.length; i++) {

                const publicacion_titulo = informesArr[i].publicacion_titulo
                const publicacion_descrip = informesArr[i].publicacion_descrip
                const file_name =   charsService.encode(informesArr[i].file_name)
                const tmp_name =    charsService.encode(informesArr[i].tmp_name)
                const file_name_dec = informesArr[i].file_name

                arrShow.push({
                        //idevento_violencia_mun,
                        publicacion_titulo,
                        publicacion_descrip,
                        file_name,
                        tmp_name,
                        file_name_dec,
                })
          }

          return arrShow
        },

    },
    async mounted() {
        await this.getConoceDerechos()
        this.arrInformes = await this.getAllEventosxMun()
        window.scrollTo(0, 0)
    }
}
</script>
<style scoped>
    
    .article-body {
        padding: 20px 0px 0px 20px;

        text-align: justify;
    }


/*   seccion de sombreado de la Imagen */
    .gradient {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 100;
        bottom: 0;
        background: rgb(0,0,0);
        background: linear-gradient(90deg, rgba(0,0,0,0.7679446778711485) 0%, rgba(0,0,0,0.7175245098039216) 35%, rgba(0,0,0,0.039653361344537785) 83%);
             
        
    }
    .gradient-creditos {
       /* background: rgb(255, 0, 0); */
/*        background: linear-gradient(180deg, rgba(255,255,255,.10) 100%, rgba(0,0,0,.50) 50%, rgba(0, 0, 0, 0.5) 100%, rgba(255,255,255,.50) 100%); */
        /*background: linear-gradient(180deg, rgba(255,255,255, 0.10) 100%, rgba(255,255,255, 0.50) 100%, rgba(255, 255, 255, 0.5) 100%, rgba(255,255,255,.50) 100%);*/
        background: rgba(39, 25, 25, 0.208);
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 100;
        bottom: 0;
    }

    .carousel-caption, .carousel-control {
        color: #ffffff;
        text-shadow: 0px 0px 2px rgb(0, 0, 0);
        vertical-align:text-top;
        z-index: 100;
        width: 100%;
        height: 100%;
        padding: 0px 0px 0px 0px;
    }
    
    d-block {
        width: 100%;
    }

   

    /*   inicia configuracion de tabla con encabezados fijos */
    table {
        border-collapse: collapse;
        width: 100%;
    }
    th {
    
        background: #30156B;      /* #30156B   */
        text-align: center;
        font-size:16px;
        position: sticky;
        top: 0; /* Don't forget this, required for the stickiness */
        box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
    }
    td {
        padding: 8px;
        text-align: left;
        border-bottom: 1px solid #ddd;
        font-size:16px;
        color:#ffffff;
    }
    td:hover {color:#ffffff; background-color: rgb(173, 0, 232,.90);}
    tr{
        color:#FFFFFF;
    } 

/* TEXTO RESPONSIVO */
            @media (min-width:320px)  { 
            /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
                .panel-p{
                    height: 830px;
                }
                .contenido_top{     /*Mueve Texto y tabla de datos */
                    top: 10px;
                    left: 0px;
                }
                .card-contenedor-izq{
                    top: 70px;
                }
                .card-contenedor-der{
                    margin-top: 150px;
                    background-color:rgb(185, 149, 255,.70);
                }
                .txt-contenido-responsivoN{
                    color:rgb(255, 255, 255);
                    font-size: 18px;
                    font-weight:bold;
                }
                
                .Imagen_responsive {
                    width: 100%;
                    height: 425px;
                }
                img {
                    width: 100%;
                    height: 425px;
                }
                .padre {
                    position: relative;
                }
                .hijo {
                    color: rgb(222, 219, 219);
                    position: absolute;
                    text-align: end;
                    top: 100%;
                    width: 100%;
                    margin: -5% 0 0 -8%;
                    font-size: 12px;
                }


           }
          @media (min-width:480px)  { 
          /* smartphones, Android phones, landscape iPhone */ 
                .panel-p{
                    height: 750px;
                }
                .contenido_top{     /*Mueve Texto y tabla de datos */
                    top: 10px;
                    left: 0px;
                }
                .card-contenedor-izq{
                    top: 70px;
                }
                .card-contenedor-der{
                    margin-top: 150px;
                    
                    background-color:rgb(185, 149, 255, 0.99);
                }
                .txt-contenido-responsivoN{
                    color:rgb(255, 255, 255);
                    font-size: 20px;
                    font-weight: lighter;
                }
                
                .Imagen_responsive {
                    width: 100%;
                    height: 400px;
                }
                img {
                    width: 100%;
                    height: 400px;
                }
                .padre {
                    position: relative;
                }
                .hijo {
                    color: rgb(222, 219, 219);
                    position: absolute;
                    text-align: end;
                    top: 100%;
                    width: 100%;
                    margin: -5% 0 0 -8%;
                    font-size: 12px;
                }
          }
          @media (min-width:600px)  { 
          /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */ 
                .panel-p{
                    height: 750px;
                }
                .contenido_top{     /*Mueve Texto y tabla de datos */
                    top: 130px;
                    left: 0px;
                }
                .card-contenedor-izq{
                    top: 28px;
                }
                .card-contenedor-der{
                    margin-top: 150px;
                    background-color:rgb(185, 149, 255, 0.99);
                }
                .txt-contenido-responsivoN{
                    color:rgb(255, 255, 255);
                    font-size: 24px;
                    font-weight: lighter;
                }
                
                .Imagen_responsive {
                    width: 100%;
                    height: 750px;
                }
                img {
                    height: 750px;
                    display: block;
                    margin: 0 auto;
                    max-width: 100%;
                    width: 100%;
                    filter: brightness(150%);
                }
                .padre {
                    position: relative;
                }
                .hijo {
                    color: rgb(222, 219, 219);
                    position: absolute;
                    text-align: end;
                    top: 100%;
                    width: 100%;
                    margin: -3% 0 0 -5%;
                    font-size: 12px;
                }

          }
          @media (min-width:801px)  { 
          /* tablet, landscape iPad, lo-res laptops ands desktops */ 
                .panel-p{
                    height: 750px;
                }
                .contenido_top{     /*Mueve Texto y tabla de datos */
                    top: 140px;
                    left: 0px;
                }
                .card-contenedor-izq{
                    top: 28px;
                }
                .card-contenedor-der{
                    margin-top: 30px;
                    background-color:rgb(255, 255, 255, 0);
                }
                .txt-contenido-responsivoN{
                    color:rgb(255, 255, 255);
                    font-size: 24px;
                    font-weight: lighter;
                }
                
                .Imagen_responsive {
                    width: 100%;
                    height: 750px;
                }
                img {
                    height: 750px;
                    display: block;
                    margin: 0 auto;
                    max-width: 100%;
                    width: 100%;
                    filter: brightness(150%);
                }
                .padre {
                    position: relative;
                }
                .hijo {
                    color: rgb(222, 219, 219);
                    position: absolute;
                    text-align: end;
                    top: 100%;
                    width: 100%;
                    margin: -3% 0 0 -5%;
                    font-size: 13px;
                }

          }
          @media (min-width:1025px) { 
          /* big landscape tablets, laptops, and desktops */ 
                .panel-p{
                    height: 750px;
                }
                .contenido_top{     /*Mueve Texto y tabla de datos */
                    top: 180px;
                    left: 0px;
                }
                .card-contenedor-izq{
                    top: 28px;
                }
                .card-contenedor-der{
                    margin-top: 30px;
                    background-color:rgb(255, 255, 255, 0);
                }
                .txt-contenido-responsivoN{
                    color:rgb(255, 255, 255);
                    font-size: 24px;
                    font-weight: lighter;
                    
                }
                
                .Imagen_responsive {
                    width: 100%;
                    height: 750px;
                }
                img {
                    height: 750px;
                    display: block;
                    margin: 0 auto;
                    max-width: 100%;
                    width: 100%;
                    filter: brightness(150%);
                }
                .padre {
                    position: relative;
                }
                .hijo {
                    color: rgb(222, 219, 219);
                    position: absolute;
                    text-align: end;
                    top: 100%;
                    width: 100%;
                    margin: -3% 0 0 -5%;
                    font-size: 14px;
                }


          }
          @media (min-width:1281px) { 
          /* hi-res laptops and desktops */
                .panel-p{
                    height: 750px;
                }
                .contenido_top{     /*Mueve Texto y tabla de datos */
                    top: 180px;
                    left: 0px;
                }
                .card-contenedor-izq{
                    top: 28px;
                    
                }
                .card-contenedor-der{
                    margin-top: 30px;
                    background-color:rgb(255, 255, 255, 0);
                }
                .txt-contenido-responsivoN{
                    /*color:rgb(255, 255, 255); */
                    padding: 0% 3% 0% 3%;
                    color:rgb(255, 255, 255);
                    font-size: 28px;
                    font-weight:lighter;
                   /* -webkit-text-stroke: 1px #afaeae;
                     text-shadow: 30px 10px 40px #000000; */

                }
                
                .Imagen_responsive {
                    width: 100%;
                    height: 750px;
                }
                img {
                    height: 750px;
                    display: block;
                    margin: 0 auto;
                    max-width: 100%;
                    width: 100%;
                    filter: brightness(150%);
                }
                .padre {
                    position: relative;
                }
                .hijo {
                    color: rgb(255, 255, 255);
                    
                    position: absolute;
                    text-align: end;
                    top: 100%;
                    width: 100%;
                    margin: -3% 0 0 -5%;
                    font-size: 16px;
                }
          
 
          }
</style>