<template>
    <div class="mainDiv">
        <Navbar />
    </div>  


    <div class="article-body" style="overflow: hidden;">  
      <div class="row">
        <!-- Panel del mapa -->
        <div class="col-lg-12">
          <Imagen />
          <Geolocalizacion />   
          <Dashboard />
      
        </div>     
      </div>     
    </div>     

    <div class="mainDiv">
        <NavbarBottom />
    </div>  



</template>

<script>

import Navbar from '@/components/Common/Navbar'
import NavbarBottom from '@/components/Common/NavbarBottom'
import Imagen from '@/components/Principal/Imagen';
import Geolocalizacion from '@/components/Principal/Geolocalizacion';
import Dashboard from '@/components/Principal/Dashboard';


export default {
  components: {
    Navbar,
    NavbarBottom,
    Imagen,
    Geolocalizacion,
    //Interv_reg_est,
    Dashboard
  },
  mounted() {
    window.scrollTo(0, 0)
  }
}
</script>
<style scoped> 

</style>