/* Regresa todos los registros de la tabla 'usuarios' en la base de datos */

import simejuvApi from '../api/simejuvApi'
import getAccesoByUser from './getAccesoByUser'

const updateDataByUserId = async( table, id, data ) => {

    const getIdUsr = await getAccesoByUser( id )

    const idAcc = getIdUsr[0].id;

    const promiseArr = [
        simejuvApi.put(`/${ table }/${ idAcc }`, data)
    ]

    const [ upd ] = await Promise.all( promiseArr )

    return upd
}

export default updateDataByUserId