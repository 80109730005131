<template>
    <div class="row">
        <div class="col-md-12 text-center">
            <h5> ¿Confirma que desea borrar la publicación? </h5>
            <h6> {{ datosInforme }}  </h6>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-3 text-left">
            <input type="button" class="btn btn-sm btn-secondary active" value="No" @click="$router.go(-1)">
        </div>
        <div class="col-md-3 text-right">
            <input type="button" class="btn btn-sm btn-primary active" value="Si" @click="confirmDel()">
        </div>
        <div class="col-md-3"></div>
    </div>
</template>

<script>
import updateDataByIdCol from '@/helpers/updateDataByIdCol'
import getInformesById from '@/helpers/getInformesById'
import charsService from '@/services/CharsService';

export default {
    data() {
        return { datosInforme: '' }
    },
    methods: {
        async confirmDel() {
            const jsonData = JSON.parse(JSON.stringify( { borrar: 1 } ));
//            const delItem = await updateDataByIdCol( 'informes', charsService.decode(this.$route.params.id), jsonData )
            await updateDataByIdCol( 'informes', charsService.decode(this.$route.params.id), jsonData )
            this.$router.push('../');
        },
        async getInforme() {
            const dataInforme = await getInformesById(charsService.decode( this.$route.params.id ))

            const cadena = `${ dataInforme.publicacion_titulo },  ${ dataInforme.publicacion_autor },  ${ dataInforme.publicacion_fecha }`;

            return cadena
        }
    },
    async mounted() {
        this.datosInforme = await this.getInforme();
        // this.getInforme()
        window.scrollTo(0, 0)
    }
}
</script>
<style scoped>
input {
    padding: 5px 40px;
    margin: 25px 0 50px;
}
</style>