import { createApp } from 'vue'
//import ElementPlus from 'element-plus'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/css/styles.css'
import Notifications from '@kyvg/vue3-notification'
import CKEditor from '@ckeditor/ckeditor5-vue'
import VueGtag from "vue-gtag-next";                  //Google Analytics
//import VueGoogleMaps from '@fawmi/vue-google-maps'    //Google Maps




const app = createApp(App)
//createApp(App)
app.use(router)
app.use(store)
app.use(Notifications)
app.use( CKEditor )
//app.use(ElementPlus)
app.mount('#app')

app.use(VueGtag, {           //Google Analytics
    property: {              //Google Analytics
      id: "G-YX6GD6BEL7"     //Google Analytics
    }                        //Google Analytics
});                          //Google Analytics


//Apartado de Google Maps
/*
app.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyAVkmaR0J87o_9ypbgxhUvI7QxA_SuOGx0',
        libraries: "places",
        // language: 'de',
    },
})
*/
if (!localStorage.getItem('Nidioma')) {
    localStorage.setItem("Nidioma", '1')
}
