<template>
     <h4 class="mt-0">Agregar una publicación</h4>
     <hr>
     <div class="row mb-5">
        <div class="col-md-12">
            <form>
                <div class="form-group row">
                    <label class="col-md-4" to="nombre">Titulo de la publicación:</label>
                    <div class="col-md-8">
                        <input type="hidden" id="idDocumento" name="idDocumento" v-model="form.id" />
                        <input type="text" class="form-control" :class="{ validated: noValid.publicacion_titulo }" id="publicacion_titulo" name="publicacion_titulo" placeholder="Titulo de la publicación" v-model="form.publicacion_titulo" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="publicacion_descrip">Descripción de la publicación:</label>
                    <div class="col-md-8">
                        <ckeditor :editor="editor" v-model="form.publicacion_descrip" :config="editorConfig" id="publicacion_descrip"></ckeditor>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="publicacion_autor">Autor:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" id="publicacion_autor" name="publicacion_autor" placeholder="Autor" v-model="form.publicacion_autor" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="publicacion_fecha">Fecha de publicación:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" id="publicacion_fecha" name="publicacion_fecha" placeholder="Fecha de publicación" v-model="form.publicacion_fecha" />
                    </div>
                </div>

<!--
                <div hidden class="form-group row">
                    <label class="col-md-4" to="id_usuario">Usuario:</label>
                    <div class="col-md-8">
                        <input type="hidden" class="form-control" id="id_usuario" name="id_usuario" placeholder="usuario" v-model="form.id_usuario" />
                    </div>
                </div>
-->


                <!--------------------- seccion para la imagen ---------------------------------->
                <div class="form-group row">
                    <label hidden class="col-md-4" to="imagen_url">Archivo en servidor:</label>
                    <div class="col-md-8">
                        <input type="hidden" class="form-control" id="imagen_url" name="imagen_url" placeholder="Nombre en el server" v-model="form.imagen_url" />
                    </div>
                </div>
                <div class="form-group row">
                    <label hidden class="col-md-4" to="imagen_nombre">Nombre del archivo:</label>
                    <div class="col-md-8">
                        <input type="hidden" class="form-control" id="imagen_nombre" name="imagen_nombre" placeholder="nombre del archivo" v-model="form.imagen_nombre" />
                    </div>
                </div>
                <div class="form-group row">
                    <label hidden class="col-md-4" to="imagen_extension">Extensión del archivo:</label>
                    <div class="col-md-8">
                        <input type="hidden" class="form-control" id="imagen_extension" name="imagen_extension" placeholder="extensión" v-model="form.imagen_extension" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-5 col-form-label" for="archivo">Subir la imagen:</label>
                    <div class="col-md-7">
                        <div v-if="successAlertImg" class="alert alert-success alert-dismissible">
                            <a href="#" class="close" aria-label="close" @click="successAlertImg=false">&times;</a>
                            {{ successMessageImg }}
                        </div>
                        <div v-if="errorAlertImg" class="alert alert-danger alert-dismissible">
                            <a href="#" class="close" aria-label="close" @click="errorAlertImg=false">&times;</a>
                            {{ errorMessageImg }}
                        </div>
                        <input type="file" ref="imagen" />
                        <br>
                        <button type="button" @click="uploadImagen" class="btn btn-default btn-sm">Subir imagen</button>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-5"></div>
                    <div class="col-md-7">
                        <table id="tblFiles" class="table table-striped">
                            <thead class="table-striped">
                                <tr>
                                    <th>Nombre de la imagen</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><a :href="`${ ubApi }/download.php?tmpFileName=${ form.imagen_url }&fileName=${ form.imagen_nombre }`" target="_blank" title="Descargar"> {{ form.imagen_nombre }}</a></td>
                                    <td align="left"><a :href="`${ ubApi }/download.php?tmpFileName=${ form.imagen_url }&fileName=${ form.imagen_nombre }`" target="_blank" title="Descargar"><i class="glyphicon glyphicon-download"></i></a></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>






                <!--------------------- seccion para el archivo ---------------------------------->
                <div hidden class="form-group row">
                    <label class="col-md-4" to="tmp_name">Archivo en servidor:</label>
                    <div class="col-md-8">
                        <input type="hidden" class="form-control" id="tmp_name" name="tmp_name" placeholder="Nombre en el server" v-model="form.tmp_name" />
                    </div>
                </div>
                <div hidden class="form-group row">
                    <label class="col-md-4" to="file_name">Nombre del archivo:</label>
                    <div class="col-md-8">
                        <input type="hidden" class="form-control" id="file_name" name="file_name" placeholder="nombre del archivo" v-model="form.file_name" />
                    </div>
                </div>
                <div hidden class="form-group row">
                    <label class="col-md-4" to="extension">Extensión del archivo:</label>
                    <div class="col-md-8">
                        <input type="hidden" class="form-control" id="extension" name="extension" placeholder="extensión" v-model="form.extension" />
                    </div>
                </div>


                <div class="form-group row">
                    <label class="col-md-5 col-form-label" for="archivo">Subir el documento:</label>
                    <div class="col-md-7">
                        <div v-if="successAlert" class="alert alert-success alert-dismissible">
                            <a href="#" class="close" aria-label="close" @click="successAlert=false">&times;</a>
                            {{ successMessage }}
                        </div>
                        <div v-if="errorAlert" class="alert alert-danger alert-dismissible">
                            <a href="#" class="close" aria-label="close" @click="errorAlert=false">&times;</a>
                            {{ errorMessage }}
                        </div>
                        <input type="file" ref="file" />
                        <br>
                        <button type="button" @click="uploadFile" class="btn btn-default btn-sm">Guardar archivo</button>
                    </div>
                </div>
               

                <div class="form-group row">
                    <div class="col-md-5"></div>
                    <div class="col-md-7">
                        <table id="tblFiles" class="table table-striped">
                            <thead class="table-striped">
                                <tr>
                                    <th>Nombre del archivo</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    
                                    <td><a :href="`${ ubApi }/download.php?tmpFileName=${ form.tmp_name }&fileName=${ form.file_name }`" target="_blank" title="Descargar"> {{ form.file_name }}</a></td>
                                    <td align="left"><a :href="`${ ubApi }/download.php?tmpFileName=${ form.tmp_name }&fileName=${ form.file_name }`" target="_blank" title="Descargar"><i class="glyphicon glyphicon-download"></i></a></td>
                                

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-6 text-left">
                        <input type="button" class="btn btn-default active" id="regresar" name="regresar" value="Regresar" @click="goBack" />
                    </div>
                    <div class="col-md-6 text-right">
                        <input type="reset" class="btn btn-default active" id="reset" name="reset" value="Otra publicación" @click="clearSelect" :disabled="!added" />
                        <input type="button" class="btn btn-primary active" id="crear" name="crear" value="Crear" @click="addDocumento" :disabled="added" />
                    </div>
                </div>
            </form>
        </div>
    </div>



</template>


<script>
//import '@/assets/css/vuedatepickerui.css'
import axios from 'axios'

import addData from '@/helpers/addData'
import getDataByAnyDocumento from '@/helpers/getDataByAnyDocumento'
import { ubApi } from '@/api/baseUrl'
//import charsService from '@/services/CharsService';

//import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
//import '@ckeditor/ckeditor5-build-classic/build/translations/es'

import { ClassicEditor, Bold, Essentials, Italic, Mention, Paragraph, Undo, Font, FontColor, Heading, FontFamily, Indent, IndentBlock, BlockQuote, Link, List} from 'ckeditor5';
/* La opción List habilita:  'bulletedList', 'numberedList'  */
import 'ckeditor5/ckeditor5.css';


export default {
    data() {
        return {
            editor: ClassicEditor,
            editorData: '',
            editorConfig: {
              language: 'es',
              wordCount: {
                    showParagraphs: false,
                    showWordCount: true,
                    showCharCount: true,
                    countSpacesAsChars: false,
                    countHTML: false,
                    maxWordCount: -1,
                    maxCharCount: 4000
              },  
              plugins: [ Bold, Essentials, Italic, Mention, Paragraph, Undo, Font, FontColor, Heading, FontFamily, Indent, IndentBlock, BlockQuote, Link, List ],
   //           toolbar: [ 'heading', 'undo', 'redo', 'bulletedList', 'numberedList', 'fontColor', 'backgroundColor', 'bold', 'italic','fontSize', 'fontFamily'],
              fontFamily: {
                  options: [
                      'default',
                      'Helvetica',
                      'Arial',
                      'sans-serif',
                      'Courier New',
                      'Courier',
                      'monospace',
                      'Tahoma',
                      'Verdana'
                  ]
              },
              heading: {
                  options: [
                      { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                      { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                      { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                      { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                      { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                      { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                      { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' }
                  ]
              },
              fontColor: {
                  colorPicker: {
                      // Use 'hex' format for output instead of 'hsl'.
                      format: 'hex',
                      color: ['#30156B', '#542EA5','#7B62D9','#B995FF','#AD00E8','#78787A','#0D0D0D']
                  }
              },
              toolbar: {
                items: [
                  'heading',
                  '|', 'undo', 'redo',
                  '|', 'bold', 'italic',
                  '|', 'fontFamily', 'fontColor', 'backgroundColor', 'fontSize', 
                  '|', 'link', 'uploadImage', 'insertTable', 'blockQuote', 'mediaEmbed',
                  '|', 'bulletedList', 'numberedList', 'outdent', 'indent'
                  

                ]
              },
            },


            ubApi: ubApi.value,
            imagen: '',
            successAlertImg: false,
            errorMessageImg: '',
            successMessageImg: '',
            errorAlertImg: false,
            file: '',
            successAlert: false,
            errorMessage: '',
            successMessage: '',
            errorAlert: false,
            form: {
                publicacion_titulo: '',
                publicacion_descrip: '',
                publicacion_autor: '',
                publicacion_fecha: '',
                imagen_url: '',
                imagen_nombre: '',
                imagen_extension: '',
                tmp_name: '',
                file_name: '',
                extension: ''
            },
            isSelected: false,
            saveDisabled: false,
            iconImg: '',
            noValid: {
                publicacion_titulo: false,
                publicacion_descrip: false,
                publicacion_autor: false,
                publicacion_fecha: false,
                imagen_url: false,
                imagen_nombre: false,
                imagen_extension: false,
                tmp_name: false,
                file_name: false,
                extension: false,
            },
            depsArr: [],
            passDif: false,
            added: false
        }
    },
    methods: {
        goBack() {
            this.$router.go(-1)
        },
        clearSelect() {
            this.form.publicacion_titulo = ''
            this.form.publicacion_descrip = ''
            this.form.publicacion_autor = ''
            this.form.publicacion_fecha = ''
            this.form.imagen_url = ''
            this.form.imagen_nombre = ''
            this.form.imagen_extension = ''
            this.form.tmp_name = ''
            this.form.file_name = ''
            this.form.extension = ''
            this.added = false
        },
        async addDocumento() {
            this.noValid.publicacion_titulo = ( this.form.publicacion_titulo )? false : true
            this.noValid.publicacion_descrip = ( this.form.publicacion_descrip )? false : true
            this.noValid.publicacion_autor = ( this.form.publicacion_autor )? false : true
            this.noValid.publicacion_fecha = ( this.form.publicacion_fecha )? false : true
            this.noValid.imagen_url = ( this.form.imagen_url )? false : true
            this.noValid.imagen_nombre = ( this.form.imagen_nombre )? false : true
            this.noValid.imagen_extension = ( this.form.imagen_extension )? false : true
            this.noValid.tmp_name = ( this.form.tmp_name )? false : true
            this.noValid.file_name = ( this.form.file_name )? false : true
            this.noValid.extension = ( this.form.extension )? false : true
            const valPublicacion_titulo = await getDataByAnyDocumento( 'cat_documentos', 'publicacion_titulo', this.form.publicacion_titulo );
            
            if ( valPublicacion_titulo.length < 1 ){
                if ( !this.noValid.publicacion_titulo) {
                    const dataForm = {
                        publicacion_titulo: this.form.publicacion_titulo,
                        publicacion_descrip: this.form.publicacion_descrip,
                        publicacion_autor: this.form.publicacion_autor,
                        publicacion_fecha: this.form.publicacion_fecha,                    
                        imagen_url: this.form.imagen_url,
                        imagen_nombre: this.form.imagen_nombre,
                        imagen_extension: this.form.imagen_extension,
                        tmp_name: this.form.tmp_name,
                        file_name: this.form.file_name,
                        extension: this.form.extension
                    }
                    const jsonData = JSON.parse(JSON.stringify( dataForm ))
                    const addDocumento = await addData('cat_documentos', jsonData)
                    if ( addDocumento[0].res.status === 200 ) {
                        this.$notify({
                            title: 'Correcto', text: 'Se insertó correctamente la información', type: 'success', duration: 5000, speed: 1000
                        });
                        this.added = true
                    } else {
                        this.$notify({
                            title: 'Error', text: 'Ocurrió un error. Verifica los datos', type: 'error'
                        });
                    }
                } else {
                    this.$notify({
                        title: 'Error', text: 'Favor de capturar el nombre de la publicación', type: 'error'
                    });
                }
            } else {
                this.$notify({
                    title: 'Error', text: 'Ya existe una publicación registrada con ese nombre. Verificar los datos', type: 'error'
                });
            }
        },


        async uploadImagen() {
            this.imagen = this.$refs.imagen.files[0]
            var formDataImg = new FormData()
            formDataImg.append('file', this.imagen)

            let z_errorAlert = false
            let z_successAlert = false
            let z_errorMessage = ''
            let z_successMessage = ''
            //let z_userId = ''
            let z_fileValue = ''
            let z_tmpFileName = ''
            let z_baseName = ''
            let z_extension = ''
            
            // await axios.post('http://localhost/ubApi/uploadFiles.php', formData, {
            await axios.post( ubApi.value + '/uploadFiles.php', formDataImg, {
                header:{
                    'Content-Type' : 'multipart/form-data'
                }
            }).then(function(response){
                if(response.data.file == '') {
                    z_errorAlert = true
                    z_successAlert = false
                    z_errorMessage = response.data.message
                    z_successMessage = ''
                    //z_userId = ''
                    z_tmpFileName = ''
                    z_baseName = ''
                    z_extension = ''
                } else {    /* Se asignan los datos para el update*/
                    z_errorAlert = false
                    z_successAlert = true
                    z_errorMessage = ''
                    z_successMessage = response.data.message
                    //z_userId = charsService.decode(localStorage.getItem('id'))
                    z_tmpFileName = response.data.tmpFile
                    z_baseName = response.data.baseName
                    z_extension = response.data.extension
                    z_fileValue = ''
                }
            });

            this.errorAlertImg = z_errorAlert
            this.successAlertImg = z_successAlert
            this.errorMessageImg = z_errorMessage
            this.successMessageImg = z_successMessage
            this.$refs.imagen.value = z_fileValue

            if (z_successAlert) {
                this.form.imagen_url=z_tmpFileName
                this.form.imagen_nombre=z_baseName
                this.form.imagen_extension=z_extension
            }
        },

        async uploadFile() {
            this.file = this.$refs.file.files[0]
            var formData = new FormData()
            formData.append('file', this.file)

            let z_errorAlert = false
            let z_successAlert = false
            let z_errorMessage = ''
            let z_successMessage = ''
            //let z_userId = ''
            let z_fileValue = ''
            let z_tmpFileName = ''
            let z_baseName = ''
            let z_extension = ''
            
            // await axios.post('http://localhost/ubApi/uploadFiles.php', formData, {
            await axios.post( ubApi.value + '/uploadFiles.php', formData, {
                header:{
                    'Content-Type' : 'multipart/form-data'
                }
            }).then(function(response){
                if(response.data.file == '') {
                    z_errorAlert = true
                    z_successAlert = false
                    z_errorMessage = response.data.message
                    z_successMessage = ''
                    //z_userId = ''
                    z_tmpFileName = ''
                    z_baseName = ''
                    z_extension = ''
                } else {    /* Se asignan los datos para el update*/
                    z_errorAlert = false
                    z_successAlert = true
                    z_errorMessage = ''
                    z_successMessage = response.data.message
                    //z_userId = charsService.decode(localStorage.getItem('id'))
                    z_tmpFileName = response.data.tmpFile
                    z_baseName = response.data.baseName
                    z_extension = response.data.extension
                    z_fileValue = ''
                }
            });

            this.errorAlert = z_errorAlert
            this.successAlert = z_successAlert
            this.errorMessage = z_errorMessage
            this.successMessage = z_successMessage
            this.$refs.file.value = z_fileValue

            if (z_successAlert) {
                this.form.tmp_name=z_tmpFileName
                this.form.file_name=z_baseName
                this.form.extension=z_extension
            }
        },
    }
}
</script>
<style scoped>
    form {
        font-size: medium;
    }

    input, select {
        font-size: medium;
    }

    input[type=button] {
        font-size: 14px;
    }
    
    #tblFiles {
        width: 100%;
        font-size: medium;
        color: #393C3E;
    }

    #tblFiles a {
        color: #393C3E;
        text-decoration: none;
    }

    #tblFiles a i {
        color: #393C3E;
    }

    .iconImg {
        width: 20px;
        height: auto;
        margin: 0 15px 0 0;
    }

</style>