<template>
    <div class="row">
        <div class="col-md-12 text-center">
            <h5> ¿Confirma que desea borrar al usuario? </h5>
            <h6> {{ datosUser }}  </h6>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-3 text-left">
            <input type="button" class="btn btn-sm btn-secondary active" value="No" @click="$router.go(-1)">
        </div>
        <div class="col-md-3 text-right">
            <input type="button" class="btn btn-sm btn-primary active" value="Si" @click="confirmDel()">
        </div>
        <div class="col-md-3"></div>
    </div>
</template>

<script>
import updateDataByUserId from '@/helpers/updateDataByUserId'
import getUsersById from '@/helpers/getUsersById'
import charsService from '@/services/CharsService'

export default {
    data() {
        return { datosUser: '' }
    },
    methods: {
        async confirmDel() {
            const jsonData = JSON.parse(JSON.stringify( { borrar: 1 } ))
//            const delItem = await updateDataByUserId( 'acceso', charsService.decode(this.$route.params.id), jsonData )
            await updateDataByUserId( 'acceso', charsService.decode(this.$route.params.id), jsonData )
            this.$router.push('../')
        },
        async getUsuario() {
            const dataUser = await getUsersById( charsService.decode(this.$route.params.id) )

            const cadena = `${ dataUser.nombre } ${ dataUser.a_paterno } ${ dataUser.a_materno } con el correo ${ dataUser.email }`

            return cadena
        }
    },
    async mounted() {
        this.datosUser = await this.getUsuario()
        window.scrollTo(0, 0)
    }
}
</script>
<style scoped>
input {
    padding: 5px 40px;
    margin: 25px 0 50px;
}
</style>