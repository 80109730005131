<template>
    <div class="mainDiv">
        <Navbar />
        <div class="article-body" >
            <ServicioResp/>
        </div>
        <NavbarBottom />
    </div>
</template>

<script>

import Navbar from '@/components/Common/Navbar'
import ServicioResp from '@/components/ServicioResp/ServicioResp';
import NavbarBottom from '@/components/Common/NavbarBottom'

export default {
    components: {
        Navbar,
        ServicioResp,
        NavbarBottom,
    },
    data() {
        return {

        }
    },
    methods: {

    },
    async mounted() {
        window.scrollTo(0, 0)
    }
}
</script>
<style scoped>

    .article-body {
        padding: 15px;
    }
    .article-body {
        text-align: left;
    }
</style>