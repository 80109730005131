<template>
    <h4>Mantenimiento del apartado acerca de</h4>
    <hr>

    <div class="row mb-3">
         <!-- Input de búsqueda -->
        <div class="col-md-3 text-right"><label class="text-small">Buscar:</label></div>
        <div class="col-md-5 text-right"><input v-model="searchTerm" class="form-control busqueda" /></div>
    </div>
    <div class="row">
        <div class="col-md-12 datatable">
            <!-- Configuración de la tabla con los campos a mostrar -->
            <table-lite
                :is-static-mode="true"
                :columns="table.columns"
                :rows="table.rows"
                :total="table.totalRecordCount"
                :sortable="table.sortable"
                :messages="table.messages"
            ></table-lite>
            
        </div>
    </div>
</template>

<script>
import { defineComponent, reactive, ref, computed } from "vue";
// Componente de la tabla
import TableLite from "@/components/DataTable/TableLite";

// Importo los helpers (API)
import getVacercade_idioma from '@/helpers/getVacercade_idioma'
import charsService from '@/services/CharsService';

export default defineComponent({
    components: { TableLite },
    setup() {
        const searchTerm = ref("") // Variable para los datos de búsqueda
        const data = reactive([]); // Variable para los datos a mostrar en la tabla

        (async () => {
            // Se crea la variable para el arreglo haciendo la llamada a la función de la api
            const acercade_idiomaArr = await getVacercade_idioma()

            // Genero el arreglo de datos para llenar la tabla
            const datos = acercade_idiomaArr
            for (let i = 0; i < datos.length; i++) {
                data.push({
                    id: datos[i].id,
                    nom_apartado: datos[i].nom_apartado,
                    acercade_nom: datos[i].acercade_nom,
                    nom_idioma: datos[i].nom_idioma,
                    nom_corto_idioma: datos[i].nom_corto_idioma
                });
            }
        })()
        
        // Configuración de la tabla
        const table = reactive({
            // Columnas de la tabla
            columns: [
                {
                    label: "Nombre del apartado",
                    field: "nom_apartado",
                    width: "30%",
                    sortable: true,
                },
                {
                    label: "Descripción",
                    field: "acercade_nom",
                    width: "30%",
                    sortable: true,
                    display: function (row) {  //Desplegar el campo con formato en la tabla
                        return (
                           row.acercade_nom 
                        );
                    },
                },
                {
                    label: "Idioma",
                    field: "nom_idioma",
                    width: "15%",
                    sortable: true,
                },
                {
                    label: "Clave del idioma",
                    field: "nom_corto_idioma",
                    width: "15%",
                    sortable: true,
                },

                // Botones de acciones (iconos)
                {
                    label: "",
                    field: "quick",
                    width: "10%",
                    display: function (row) {
                        // Iconos de acciones
                        return (
                            // TO-DO: Hay que cambiar la ruta a la acción correspondiente (definida en en index.ts de la carpeta router)
                            // puede ser /informe/informeAdmin/edit...
                            '<a href="/acercade/acercadeAdmin/edit/'+ charsService.encode( row.id ) +'" class="text-info" title="Editar"><i class="glyphicon glyphicon-pencil"></i></a> ' + '&nbsp;&nbsp;&nbsp;' + 
                           // '<a href="/conocederechos/conocederechosAdmin/remove/'+ charsService.encode( row.id ) +'" class="text-info" title="Borrar"><i class="glyphicon glyphicon-trash"></i></a> ' + '&nbsp;&nbsp;&nbsp;' + 
                            ''
                        );
                    },
                },
            ],
            rows: computed(() => {
                // Aquí se designan las columnas en las que se realizará la búsqueda desde el input
                return data.filter(
                    (x) =>
                        x.nom_apartado.toLowerCase().includes(searchTerm.value.toLowerCase()) 
                    );
            }),
            totalRecordCount: computed(() => {
                // Se regresa el número de registros encontrados
                return table.rows.length
            }),
            sortable: {
                // Se define el orden por default
                order: "id",
                sort: "asc",
            },
            messages: {
                // Personalizo los mensajes que muestra la tabla
                pagingInfo: "Mostrando {0}-{1} de {2}",
                pageSizeChangeLabel: "Filas:",
                gotoPageLabel: "   Ir a la página:",
                noDataAvailable: "Sin datos para mostrar",
            },
        });

        return {
            searchTerm,
            table,
        }
    }
})
</script>

<style scoped>
    .card :deep .table .thead-dark th {
        color: #000;
        background-color: #fff;
        border-color: #fff;
    }
    .card :deep .table td, .card :deep .table tr{
        border: none;
    }
    .text-small {
        font-size: medium;
    }
    .busqueda {
        font-size: medium;
        height: auto;
    }
    .text-info {
        color: #343a40 !important;
        padding: 0 20px;
    }
    .datatable {
        padding-bottom: 10px;
        overflow-x: auto;
        margin-bottom: 25px;
    }
    .card {
        border: 0 !important;
    }
</style>