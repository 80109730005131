<template>
    <div class="col-lg-12">
            
            
            
            <div class="col-md-4 text-left">
                <h3>Contáctanos</h3>
                <p>Para preguntas, asistencia técnica y oportunidades de colaboración a través de la información de contacto proporcionada.</p>
                <p><i class="box-classic-icon glyphicon glyphicon glyphicon-envelope"></i> contacto @cuidandoelcamino.org</p>
            </div>
            
            
            
            <div class="col-md-8">
                <form>

                    <div class="form-group row">
                        <div class="col-md-12 text-left">
                            <label to="nombre">Nombre:</label>
                            <input type="text" class="form-control" :class="{ validated: noValid.nombre }" id="nombre" name="nombre" placeholder="Nombre" v-model="form.nombre" />
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-md-8 text-left">
                            <label to="email">Correo electrónico:</label>
                            <input type="email" class="form-control" :class="{ validated: noValid.email }" id="email" name="email" placeholder="Correo electrónico" v-model="form.email" />
                        </div>
                        <div class="col-md-4 text-left">
                            <label to="telefono">Teléfono:</label>
                            <input type="tel" class="form-control" id="telefono" name="telefono" placeholder="Teléfono" v-model="form.telefono" />
                        </div>

                    </div>

                    <div class="form-group row">
                        <div class="col-md-12 text-left">
                            <label to="mensaje">Mensaje:</label>
                            <textarea rows="10" cols="20"  type="text" class="form-control" id="mensaje" name="mensaje" placeholder="Mensaje" v-model="form.mensaje" />
                        </div>
                    </div>


                    

                    <div class="form-group row">
                        <div class="col-md-12 text-right">
                            <input type="button" class="btn btn-primary btn-sm active" id="guardar" name="guardar" value="Enviar" @click="EnviarCorreo" />
                        </div>
                    </div>
                </form>
            </div>
    </div>



</template>

<script>
import addData from '@/helpers/addData'
import sendMailService from '@/services/SendMailService'


export default {
    data() {
        return {
            form: {
                nombre: '',
                email: '',
                telefono: '',
                mensaje: ''
            },
            noValid: {
                nombre: false,
                email: false,
                tipoUsr: false,
            },
            added: false

        }
    },
    methods: {

        async clearSelect() {
            this.form.nombre = ''
            this.form.email = ''
            this.form.telefono = ''
            this.form.mensaje = ''
            this.added = false
        },
        async EnviarCorreo() {
            this.noValid.nombre = ( this.form.nombre )? false : true
            this.noValid.email = ( this.form.email )? false : true
            this.noValid.mensaje = ( this.form.mensaje )? false : true

                // Valido si los campos que son requeridos contienen información
                if ( !this.noValid.nombre && !this.noValid.email && !this.noValid.mensaje) {
                    const dataForm = {
                        nombre: this.form.nombre,
                        email: this.form.email,
                        telefono: this.form.telefono,
                        mensaje: this.form.mensaje,
                    }

                    // Valido si el formato de correo es correcto
                    const emailVal = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9\\-])+.)+([a-zA-Z0-9]{2,4})+$/
                    if ( !emailVal.test( this.form.email ) ) {
                        this.$notify({
                            title: 'Error', text: 'El Correo electrónico ingresado no es válido, favor de verificarlo', type: 'error'
                        })
                        this.noValid.email = true

                        return
                    }
                    const jsonData = JSON.parse(JSON.stringify( dataForm ))
                    // Inserto la información en la tabla de contactanos
                    const addUser = await addData('contactanos', jsonData)

                    if ( addUser[0].res.status === 200 ) {
                        this.$notify({
                            title: 'Correcto', text: 'La información se envío correctamente', type: 'success', duration: 5000, speed: 1000
                        });
                        this.added = true
//                            const envio = await sendMailService.newUser(this.form.nombre, this.form.a_paterno, this.form.email, this.form.password)
// este es el bueno                        await sendMailService.newUser(this.form.nombre, 'Paterno', this.form.email, 'password')
                        this.clearSelect()
                    } else {
                        this.$notify({
                            title: 'Error', text: 'Ocurrió un error. Verifica los datos', type: 'error'
                        });
                    }
                } else {
                    this.$notify({
                        title: 'Error', text: 'El formulario requiere atención en uno o varios campos. Favor de atenderlo', type: 'error'
                    });
                }
        },
        async EnviarCorreoBORRAR0() {
            
            
            const pssNew = 'newUser'  //document.querySelector("#new").value
            //const pssRe = document.querySelector("#renew").value
            //const specialChars = /[(@!#$%^&*)(+=._-]{1,}/

            const usrNombre = 'nombre de usuario'      //userData['nombre'];
            const usrApaterno = 'Apellido'                       // userData['a_paterno'];
            const usrEmail = 'prueba@prueba.com'                           //userData['email'];
                    //const envio = await sendMailService.newUser( usrNombre, usrApaterno, usrEmail, pssNew );
                    //await sendMailService.newUser(this.form.nombre, this.form.a_paterno, this.form.email, this.form.password)
                    await sendMailService.newUser(usrNombre, usrApaterno, usrEmail, pssNew)
                    //this.$router.go(-1)
                    this.$notify({
                        title: 'Correcto.', text: `La información se ha enviado`, type: 'success', duration: 5000, speed: 1000
                    })
        },     
    },
    async mounted() {
        //this.setData( charsService.decode( this.$route.params.id ))
        window.scrollTo(0, 0)
    }
}
</script>

<style scoped>
    form {
        font-size: medium;
    }

    input, select {
        font-size: medium;
    }

    input[type=button] {
        font-size: 14px;
    }

    #texto_inferior {
        height: 500px;
    }

    .box-classic-icon {
        top: 0px;
        padding-top: 5px;
        padding-left: 5px;
        position: relative;
        color: #ffffff;
        width: 30px;
        height: 30px;
        border-radius: 10em;
        background: linear-gradient(98.15deg, #AD00E8 10%, #AD00E8 20%);
       
    }
    .validated {
        border: 3px solid #dc3545;
    }

</style>