<template>
    <div class="mainDiv">
        <Navbar />
        <div class="mt-5 container principal text-left">
          <Login />
        </div>
    </div>
</template>

<script>
import Navbar from '@/components/Common/Navbar'
import Login from '@/components/Login/Login'

export default {
  components: {
    Navbar,
    Login
  },
  mounted() {
    window.scrollTo(0, 0)
  }
}
</script>