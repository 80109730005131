/* Regresa todos los datos del usuario con el id enviado de la tabla 'cat_servicio_resp' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getCatServicioRespById = async( id ) => {
    const promiseArr = [
        simejuvApi.get(`/cat_servicio_resp/${ id }`)
    ]

    const [ cat_servicio_resp ] = await Promise.all( promiseArr )

    return cat_servicio_resp.data
}

export default getCatServicioRespById