<template>
    <h4 class="mt-0">Mantenimiento del logotipo de  una Organizacion</h4>
    <hr>
    <div class="row mb-5">
        <div class="col-md-12">
            <form>
                <div class="form-group row">
                    <label class="col-md-4" to="nom_organizacion">Nombre de la Organización:</label>
                    <div class="col-md-8">
                        <input type="hidden" id="idorg_logos" name="idorg_logos" v-model="form.id" />
                        <input type="text" class="form-control" id="nom_organizacion" name="nom_organizacion" placeholder="Nombre de la Organización" v-model="form.nom_organizacion" />
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-md-4" to="url_organizacion">Página web de la Organización:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" id="url_organizacion" name="url_organizacion" placeholder="En formato https:// o http://" v-model="form.url_organizacion" />
                    </div>
                </div>



                <!--------------------- seccion para la imagen ---------------------------------->
                <div class="form-group row">
                    <label hidden class="col-md-4" to="imagen_url">Imagen en el servidor:</label>
                    <div class="col-md-8">
                        <input type="hidden" class="form-control" id="imagen_url" name="imagen_url" placeholder="Nombre en el server" v-model="form.imagen_url" />
                    </div>
                </div>
                <div class="form-group row">
                    <label hidden class="col-md-4" to="imagen_nombre">Nombre del archivo:</label>
                    <div class="col-md-8">
                        <input type="hidden" class="form-control" id="imagen_nombre" name="imagen_nombre" placeholder="nombre del archivo" v-model="form.imagen_nombre" />
                    </div>
                </div>
                <div class="form-group row">
                    <label hidden class="col-md-4" to="imagen_extension">Extensión del archivo:</label>
                    <div class="col-md-8">
                        <input type="hidden" class="form-control" id="imagen_extension" name="imagen_extension" placeholder="extensión" v-model="form.imagen_extension" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-5 col-form-label" for="archivo">Subir la imagen del logotipo:</label>
                    <div class="col-md-7">
                        <div v-if="successAlertImg" class="alert alert-success alert-dismissible">
                            <a href="#" class="close" aria-label="close" @click="successAlertImg=false">&times;</a>
                            {{ successMessageImg }}
                        </div>
                        <div v-if="errorAlertImg" class="alert alert-danger alert-dismissible">
                            <a href="#" class="close" aria-label="close" @click="errorAlertImg=false">&times;</a>
                            {{ errorMessageImg }}
                        </div>
                        <input type="file" ref="imagen" />
                        <br>
                        <button type="button" @click="uploadImagen" class="btn btn-default btn-sm">Subir imagen</button>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-5"></div>
                    <div class="col-md-7">
                        <table id="tblFiles" class="table table-striped">
                            <thead class="table-striped">
                                <tr>
                                    <th>Nombre de la imagen</th>
                                   
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><a :href="`${ ubApi }/download.php?tmpFileName=${ form.imagen_url }&fileName=${ form.imagen_nombre }`" target="_blank" title="Descargar"> {{ form.imagen_nombre_dec }}</a></td>
                                   
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>






                <div class="form-group row">
                    <div class="col-md-6 text-left">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateOrg_logos" /> -->
                        <input type="button" class="btn btn-default btn-sm active" id="regresar" name="regresar" value="Regresar" @click="goBack" />
                    </div>
                    <div class="col-md-6 text-right">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateOrg_logos" /> -->
                        <input type="button" class="btn btn-primary btn-sm active" id="guardar" name="guardar" value="Guardar" @click="updateOrg_logos" />
                    </div>
                </div>
            </form>

        </div>
    </div>
</template>

<script>
import '@/assets/css/vuedatepickerui.css'



// import axios from 'axios'
import getOrg_logosById from '@/helpers/getOrg_logosById'
import updateData from '@/helpers/updateData'

import axios from 'axios'
import { ubApi } from '@/api/baseUrl'
import charsService from '@/services/CharsService';

export default {
    data() {
        return {
            ubApi: ubApi.value,
            id: '',
            data: [],
            datos: [],
            
            imagen: '',
            successAlertImg: false,
            errorMessageImg: '',
            successMessageImg: '',
            errorAlertImg: false,

            form: {
                id: '',
                nom_organizacion: '',
                url_organizacion: '',

                imagen_url: '',
                imagen_url_dec: '',
                imagen_nombre: '',
                imagen_nombre_dec: '',
                imagen_extension: '',
            },
            noValid: {
                nom_organizacion: false,
                url_organizacion: false,
                imagen_url: false,
            },
            iconImg: '',

        }
    },
    methods: {
      
        async setData( id ) {
            const datos = await getOrg_logosById( id )

            this.form.id = datos.id
            this.form.nom_organizacion = datos.nom_organizacion
            this.form.url_organizacion = datos.url_organizacion

            this.form.imagen_url = charsService.encode(datos.imagen_url)
            this.form.imagen_url_dec = datos.imagen_url
            this.form.imagen_nombre = charsService.encode(datos.imagen_nombre)
            this.form.imagen_nombre_dec = datos.imagen_nombre
            this.form.imagen_extension = datos.imagen_extension

        },

        async updateOrg_logos() {
            this.noValid.nom_organizacion = ( this.form.nom_organizacion )? false : true
            this.noValid.url_organizacion = ( this.form.url_organizacion )? false : true
            this.noValid.imagen_url = ( this.form.imagen_url )? false : true

            if ( !this.noValid.nom_organizacion) {
                if ( !this.noValid.url_organizacion) {
                    if ( !this.noValid.imagen_url) {

            
            
            
                        const dataForm = {
                            nom_organizacion: this.form.nom_organizacion,
                            url_organizacion: this.form.url_organizacion,

                            imagen_url: this.form.imagen_url_dec,
                            imagen_nombre: this.form.imagen_nombre_dec,
                            imagen_extension: this.form.imagen_extension,

                        };

                        const jsonData = JSON.parse(JSON.stringify( dataForm ));

                        const updAcc = await updateData('org_logos', this.form.id, jsonData)

                        if ( updAcc[0].res == 1 ){
                            this.$notify({
                                title: 'Correcto', text: 'Se actualizó correctamente la información', type: 'success', duration: 5000, speed: 1000
                            });
                        } else {
                            this.$notify({
                                title: 'Error', text: 'Ocurrió un error. Verifica los datos', type: 'error'
                            });
                        }
                    } else {
                        this.$notify({
                            title: 'Error', text: 'Favor de subir la imagen', type: 'error'
                        });
                    }
                } else {
                    this.$notify({
                        title: 'Error', text: 'Favor de capturar la Url de la Organización', type: 'error'
                    });
                }
            } else {
                this.$notify({
                    title: 'Error', text: 'Favor de capturar el nombre de la Organización', type: 'error'
                });
            }

        },


        async uploadImagen() {
            this.imagen = this.$refs.imagen.files[0]
            var formDataImg = new FormData()
            formDataImg.append('file', this.imagen)

            let z_errorAlert = false
            let z_successAlert = false
            let z_errorMessage = ''
            let z_successMessage = ''
            let z_userId = ''
            let z_fileValue = ''
            let z_tmpFileName = ''
            let z_baseName = ''
            let z_extension = ''
            
            // await axios.post('http://localhost/ubApi/uploadFiles.php', formDataImg, {
            await axios.post( ubApi.value + '/uploadFiles.php', formDataImg, {
                header:{
                    'Content-Type' : 'multipart/form-data'
                }
            }).then(function(response){
                if(response.data.file == '') {
                    z_errorAlert = true
                    z_successAlert = false
                    z_errorMessage = response.data.message
                    z_successMessage = ''
                    z_userId = ''
                    z_tmpFileName = ''
                    z_baseName = ''
                    z_extension = ''
                } else {    /* Se asignan los datos para el update*/
                    z_errorAlert = false
                    z_successAlert = true
                    z_errorMessage = ''
                    z_successMessage = response.data.message
                    z_userId = charsService.decode(localStorage.getItem('id'))
                    z_tmpFileName = response.data.tmpFile
                    z_baseName = response.data.baseName
                    z_extension = response.data.extension
                    z_fileValue = ''
                    //alert ("entro a asignar archuvos:" + z_tmpFileName)
                }
            });

            this.errorAlertImg = z_errorAlert
            this.successAlertImg = z_successAlert
            this.errorMessageImg = z_errorMessage
            this.successMessageImg = z_successMessage
            this.$refs.imagen.value = z_fileValue

            if (z_successAlert) {
                // AvanceAcciones módulo para tabla de archivos
/*
                const jsonInsFile = JSON.parse(JSON.stringify({
                    "id_usuario": z_userId,
                    "imagen_url": z_tmpFileName,
                    "imagen_nombre": z_baseName,
                    "imagen_extension": z_extension
                }))
*/              
                this.form.id_usuario=z_userId
                this.form.imagen_url=z_tmpFileName
                this.form.imagen_url_dec=z_tmpFileName
                this.form.imagen_nombre=z_baseName
                this.form.imagen_nombre_dec=z_baseName
                this.form.imagen_extension=z_extension

            }
        },

        goBack() {
            this.$router.go(-1)
        }

    },
    async mounted() {
        this.setData(charsService.decode( this.$route.params.id ))

        window.scrollTo(0, 0)
    }
}
</script>


<style scoped>
    form {
        font-size: medium;
    }

    input, select {
        font-size: medium;
    }

    input[type=button] {
        font-size: 14px;
    }
    
    #tblFiles {
        width: 100%;
        font-size: medium;
        color: #393C3E;
    }

    #tblFiles a {
        color: #393C3E;
        text-decoration: none;
    }

    #tblFiles a i {
        color: #393C3E;
    }

    .iconImg {
        width: 20px;
        height: auto;
        margin: 0 15px 0 0;
    }

</style>