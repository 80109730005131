<template>
    
    <div class="row">
        <!-- Panel del mapa -->
        
        <div class="col-lg-12"  style="box-shadow: 5px 5px 15px gray; border-radius: 3px; padding-bottom: 0px;">
            <br>
            <div class="card-header" style="padding: 50px 0px 0px 0px;">    
                    <div class="col-md-4">
                        <div class="card-header" style="padding: 0px 0px 0px 1px;">
                                <!--<h2 style="color: #542EA5;">Mapa de violencia</h2>-->
                                <span><p class="card-text" v-html="this.descripcion_mapa"></p></span>
                                <button class="btn" style="background-color: #542EA5; color: #ffff;"    @click="ejecuta_link"> {{this.btn_acercareportes_titulo}} <i class="glyphicon glyphicon-arrow-right"></i></button>
                                <hr>
                        </div>
                    </div>
                    <div class="col-md-8"  style="">
                        <div class="row">
                            <div  style="padding: 0px 0px 0px 0px; box-shadow: 0px 0px 1px gray;">
                                <iframe class="iframe-config" :src="url_mapa" width="100%" frameborder="0" marginheight="0" loading="lazy" allow="fullscreen" tabindex="0" ></iframe>
                            </div>
                        </div>
                    </div>
            </div>        
                
        </div>
            
        <!-- Panel del Cuadro de datos -->    
        <div class="col-lg-12"  style="box-shadow: 5px 5px 15px gray; border-radius: 3px; padding-bottom: 0px;">
            <div class="card-header" style="padding: 0px 20px 0px 15px;">
                <span><p class="card-text" style="color: #542EA5;" v-html="this.descripcion_tabla"></p></span>
            </div>
            <div>
                <div  style="padding: 5px 20px 5px 15px; box-shadow: 5px 5px 15px gray;">
                    <iframe class="iframe-config" :src="url_tabla" width="100%" frameborder="0" marginheight="0" allow="fullscreen"></iframe>
                </div>
            </div>
        </div>




        
        
        
    </div>    
    



</template>

<script>
//import axios from 'axios'
//import getCat_principal from '@/helpers/getCat_principal';
import getCat_principalById from '@/helpers/getCat_principalById'

//import moment from 'moment'
//import { ubApi, icons } from '@/api/baseUrl'

export default {
  components: {

  },
  data() {
        return {
            descripcion_mapa: '',
            btn_acercareportes_titulo: '',
            btn_acercareportes_url: '',

            url_mapa: '',
            descripcion_tabla: '',
            url_tabla: '',
 
        }
  },
  methods: {
      log(a) {
        console.log(a);
      },
      
      async getCat_principal() {
            //const dataInterv_param = await getCat_principal();
            const datos = await getCat_principalById(  localStorage.getItem("Nidioma") )
            this.descripcion_mapa = datos.descripcion_mapa;
            this.btn_acercareportes_titulo = datos.btn_acercareportes_titulo
            this.btn_acercareportes_url = datos.btn_acercareportes_url

            this.url_mapa = datos.url_mapa;
            this.descripcion_tabla = datos.descripcion_tabla;
            this.url_tabla = datos.url_tabla;
            return
      },
      async ejecuta_link() {
            this.$router.push(this.btn_acercareportes_url)
      },

  },
  
  async mounted() {
        //await this.getInterv_param();
        await this.getCat_principal();
        //await this.getCatalogos()


        //this.$gtag.event('Geolocalización de Oportunidades');     //Registra el Evento en Google Analytics con nombre
  }
}
</script>

<style scoped>
  .callout {
      background-color: rgba(250, 250, 250, 0.5);
  }
  .card {
      font-size: medium;
  }
  .icoLink {
      color: #212529;
  }

  .dontShow {
      display: none;
  }

  .btnCond {
      margin-bottom: 40px;
  }



  h5 {
      color: #10293F;       /*  antes 9D2449 */
  }

  .panel {
      font-size: medium;
  }

  .titulo {
      font-weight: bold;
      line-height: 40px;
  }
  .linea_gris {
      width: 100%;
  }
  .leaflet-popup-content {
    height: 350px;
    overflow-y: scroll;
  }
  .small-popup {
    max-width: 600px;
    max-height: 300px;
  }


 .card-header {
    
 }




/*   inicia configuracion de tabla con encabezados fijos */
table {
  border-collapse: collapse;
  width: 100%;
}
th {
  
  background: #30156B;      /* #30156B   */
  text-align: center;
  font-size:14px;
  position: sticky;
  top: 0; /* Don't forget this, required for the stickiness */
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}
 td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  font-size:14px;
  color:#0D0D0D;
}
 tr:hover {color:#FFFFFF; background-color: #ceccd3;}
 tr{
  color:#FFFFFF;
 } 





/* APARTADO RESPONSIVO */
          @media (min-width:320px)  { 
            /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
                .iframe-config{
                    height: 550px;
                }
           }
          @media (min-width:480px)  { 
          /* smartphones, Android phones, landscape iPhone */ 
                .iframe-config{
                    height: 500px;
                }
          }
          @media (min-width:600px)  { 
          /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */ 
                .iframe-config{
                    height: 500px;
                }
          }
          @media (min-width:801px)  { 
          /* tablet, landscape iPad, lo-res laptops ands desktops */ 
                .iframe-config{
                    height: 500px;
                }
          }
          @media (min-width:1025px) { 
          /* big landscape tablets, laptops, and desktops */ 
                .iframe-config{
                    height: 500px;
                }
          }
          @media (min-width:1281px) { 
          /* hi-res laptops and desktops */
                .iframe-config{
                    height: 800px;
                }
          } 


</style>