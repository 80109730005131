/* Regresa todos los registros de la vista 'v_acceso' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getAllUsers = async() => {
    const promiseArr = [
        simejuvApi.get(`/v_acceso?transform=1&filter=borrar,lt,1`)
    ]

    const [ users ] = await Promise.all( promiseArr )

    return [
        { usuarios: users.data.v_acceso }
    ]
}

export default getAllUsers