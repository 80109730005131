/* Regresa todos los registros de la tabla 'usuarios' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const updateData = async( table, id, data ) => {
    const promiseArr = [
        simejuvApi.put(`/${ table }/${ id }`, data)
    ]

    const [ upd ] = await Promise.all( promiseArr )

    return [
        { res: upd.data }
    ]
}

export default updateData