<template>
    <!-- TODO: Ajustar nuevo submenú -->
    <div class="txt-responsivo" style="background-color: #542EA5;">
    <nav class="navbar navbar-inverse sub-navbar-bottom" style="background-color: #542EA5;">
        
        <!-- Menu principal -->
        <div class="" >    <!--  La classe container pone padding al Menu -->

            <!--<div class="collapse navbar-collapse" id="MenuBottom" style="background-color: rgba(0,0,0, .0); width:50%; margin-left: auto; margin-right: auto;">-->
            <div class="collapse navbar-collapse" id="MenuBottom" style="background-color: rgba(0,0,0, .0);">    
                <nav class="nav">                
                    <ul class="  navbar-margen-bottom" style="">
                            <li class="nav-item" style="">
                                <router-link class="nav-link subnav-link persSJ" to="/" title=""><p class="btn-nav-bottom" v-html="this.Gbtn_inicio_desc"></p></router-link>
                            </li>
                            <li class="nav-item">
                                <router-link class="nav-link subnav-link persSJ" to="/acercade" title=""><p class="btn-nav-bottom" v-html="this.Gbtn_acerca_desc"></p></router-link>
                            </li>

                            <li class="nav-item">
                                <router-link class="nav-link subnav-link persSJ" to="/servicioresp" title=""><p class="btn-nav-bottom" v-html="this.Gbtn_directorio_desc"></p></router-link>
                            </li>
                            <li class="nav-item">
                                <router-link class="nav-link subnav-link persSJ" to="/conocetusderechos"  title=""><p class="btn-nav-bottom" v-html="this.Gbtn_informacion_desc"></p></router-link>
                            </li>
                            <li class="nav-item">
                                <router-link class="nav-link subnav-link persSJ" :to="`/adminSec`"  title=""><p class="btn-nav-bottom" v-html="this.Gbtn_registro_desc"></p></router-link>
                            </li>
                    </ul>
                </nav>    
            </div>
        </div>
    </nav>
    <div style="height: 150px;">
        <br>
        <div style="border-top: 1px solid white; width:70%; margin-left: auto; margin-right: auto;">
            <h5 style="color: #ffffff; text-align: center;">COPYRIGHT © 2024 CUIDANDO EL CAMINO</h5>
        </div>
    </div>
    </div>
</template>

<script>
import charsService from '@/services/CharsService'
//import NavbarIdiomas from '@/components/Common/NavbarIdiomas';
import getCat_MenuPaginaById from '@/helpers/getCat_MenuPaginaById'

export default {
    components: {
//        NavbarIdiomas,
    },    
    props: {
        user: Boolean
    },
    data() {
        return {
            Gbtn_inicio_desc: localStorage.getItem("Gbtn_inicio_desc"),
            
            Gbtn_acerca_desc: localStorage.getItem("Gbtn_acerca_desc"),

            Gbtn_directorio_desc: localStorage.getItem("Gbtn_directorio_desc"),
            Gbtn_informacion_desc: localStorage.getItem("Gbtn_informacion_desc"),
            Gbtn_registro_desc: localStorage.getItem("Gbtn_registro_desc"),
            
            userId: charsService.decode(localStorage.getItem('id')),
            userIdEnc: localStorage.getItem('id'),
            logged: this.user
        }
    },
    methods: {
        async valida_menuPagina() {
            //alert (localStorage.getItem("Gbtn_inicio_desc"))
            //if (!localStorage.getItem("Gbtn_inicio_desc")){
                //alert (datos.btn_acerca_desc)
                const datos = await getCat_MenuPaginaById(  localStorage.getItem("Nidioma") )
                localStorage.setItem("Gbtn_inicio_desc", datos.btn_inicio_desc)
                localStorage.setItem("Gbtn_acerca_desc", datos.btn_acerca_desc)
                localStorage.setItem("Gbtn_directorio_desc", datos.btn_directorio_desc)
                localStorage.setItem("Gbtn_informacion_desc", datos.btn_informacion_desc)
                localStorage.setItem("Gbtn_registro_desc", datos.btn_registro_desc)
            //}
            this.Gbtn_inicio_desc = localStorage.getItem("Gbtn_inicio_desc")
            this.Gbtn_acerca_desc = localStorage.getItem("Gbtn_acerca_desc")
            this.Gbtn_directorio_desc = localStorage.getItem("Gbtn_directorio_desc")
            this.Gbtn_informacion_desc = localStorage.getItem("Gbtn_informacion_desc")
            this.Gbtn_registro_desc = localStorage.getItem("Gbtn_registro_desc")
 
            return
        },
    },  
    mounted() {
        if ( localStorage.getItem('nombre') ) {
            this.logged = true
            this.$route.params.tUser = this.userId
        }
        this.valida_menuPagina()
        //alert (localStorage.getItem("Gbtn_inicio_desc"))
        
        
    }
}
</script>

<style>
    .txt-responsivo{
        font-size: 20px;  /**No funciona, revisarla */
    }
    .sub-navbar-bottom {
        height: 100px;
        
    }

    .persSJ {
        height:30px;
        display: flex !important;
        align-items: center;
        text-decoration: none;
        background-color: #542EA5;
        color:#fff;
        margin-top: 40px;
        margin-right: 15px;
        transition: background-color 0.5s;
    }
    .persSJ:hover {
        text-decoration: none;
        background-color: #AD00E8;
        color:#fff;
        border-radius: 10px; 
       
    } 

    .btn-nav-bottom {
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 25px;
    } 

   

    nav > ul {
        display: flex;
        justify-content: center;
    }
    .navbar-margen-bottom{
        margin-top: 3%;
        margin-right: 2%;
        background-color: rgb(255, 255, 255, .0); 
    }  

    /* SECCION PARA RESPONSIVIDAD DE LA PLATAFORMA */
        @media (min-width:320px)  { 
        /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
    
        }
        @media (min-width:480px)  { 
        /* smartphones, Android phones, landscape iPhone */ 

        }
        @media (min-width:600px)  { 
        /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */ 
        }
        @media (min-width:801px)  { 
        /* tablet, landscape iPad, lo-res laptops ands desktops */ 
            

        }
        @media (min-width:1025px) { 
        /* big landscape tablets, laptops, and desktops */ 
             

        }
        @media (min-width:1281px) { 
        /* hi-res laptops and desktops */
                        

        }
</style>