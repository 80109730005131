import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

// Aquí importo la vista a utilizar (Se puede seguir en la carpeta views)
import Home from '../views/Home.vue'


import Acercade from '../views/Acercade.vue'
//import Principal from '../views/Principal.vue'
import ConoceTusDerechos from '../views/ConoceTusDerechos.vue'
import ServicioResp from '../views/ServicioResp.vue'   
import Login from '../views/Login.vue'
import Logout from '../views/Logout.vue'
import UserAdmin from '../views/UserAdmin.vue'
import ActionsAdmin from '../views/ActionsAdmin.vue'
import AdminSec from '../views/AdminSec.vue'

import Org_logosAdmin from '../views/Org_logosAdmin.vue'
import ActionsOrg_logosAdmin from '../views/ActionsOrg_logosAdmin.vue'
import AcercadeAdmin from '../views/AcercadeAdmin.vue'
import ActionsAcercadeAdmin from '../views/ActionsAcercadeAdmin.vue'
import ServicioRespAdmin from '../views/ServicioRespAdmin.vue'
import ActionsServicioRespAdmin from '../views/ActionsServicioRespAdmin.vue'
import ConoceDerechosAdmin from '../views/ConoceDerechosAdmin.vue'
import ActionsConoceDerechosAdmin from '../views/ActionsConoceDerechosAdmin.vue'
import ConoceDerechosDoctosAdmin from '../views/ConoceDerechosDoctosAdmin.vue'
import ActionsConoceDerechosDoctosAdmin from '../views/ActionsConoceDerechosDoctosAdmin.vue'
import RegistroInfAdmin from '../views/RegistroInfAdmin.vue'
import ActionsRegistroInfAdmin from '../views/ActionsRegistroInfAdmin.vue'

import MenuPaginaAdmin from '../views/MenuPaginaAdmin.vue'
import ActionsMenuPaginaAdmin from '../views/ActionsMenuPaginaAdmin.vue'

import Recover from '../views/Recover.vue'
import CambioPassword from '../views/CambioPassword.vue'
import PrincipalAdmin from '../views/PrincipalAdmin.vue'
import ActionsPrincipalAdmin from '../views/ActionsPrincipalAdmin.vue'

// Se genera el arreglo con las rutas permitidas
const routes: Array<RouteRecordRaw> = [

  // Inicio
  {
    path: '/',
    name: 'Inicio',
    component: Home
  },


  // Acerca de 
  {
    path: '/acercade',
    name: 'Acercade',
    component: Acercade
  },  


  {
    path: '/principal/',
    redirect: '/principal/geolocalizacion'
  },
 
  /*
  {
    path: '/principal/:mod',
    name: 'Principal',
    component: Principal
  },
*/
   
  {
    path: '/conocetusderechos',
    name: 'ConoceTusDerechos	',
    component: ConoceTusDerechos
  },

  
  {
    path: '/servicioresp',           
    name: 'ServicioResp',
    component: ServicioResp
  },

  
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  },
  {
    path: '/adminSec',
    name: 'AdminSec',
    component: AdminSec
  },
  {
    path: '/user/usrAdmin',
    name: 'UserAdmin',
    component: UserAdmin
  },
  {
    path: '/user/usrAdmin/:action',
    name: 'ActionsAdmin',
    component: ActionsAdmin
  },
  {
    path: '/user/usrAdmin/:action/:id?',
    name: 'ActionsAdmin',
    component: ActionsAdmin
  },


  {
    path: '/user/usrAdmin',
    name: 'UserAdmin',
    component: UserAdmin
  },

  // ***********************MenuPagina*********************************
  // Aquí configuro las rutas (url) para las acciones de acercade
  // Solo lo dejo declarado, hay que desarrollar los archivos
  {
    // Se declara la url a utilizar
    path: '/menupagina/menupaginaAdmin',
    // Se le asigna un nombre
    name: 'menupaginaAdmin',
    // Se indica el componente a usar ( Se importa al inicio de éste archivo )
    component: MenuPaginaAdmin
  },
  
  
  {
    path: '/menupagina/menupaginaAdmin/:action',
    name: 'ActionsMenuPaginaAdmin',
    component: ActionsMenuPaginaAdmin
  },
  {
    // :action - inica la accion
    // :id? - parámetro opcional
    path: '/menupagina/menupaginaAdmin/:action/:id?',
    name: 'ActionsMenuPaginaAdmin',
    component: ActionsMenuPaginaAdmin
  },


  // ***********************Org_logos*********************************
  // Aquí se hace la llamada a la vista a utilizar para esta url
  {
    // Se declara la url a utilizar
    path: '/org_logos/org_logosAdmin',
    // Se le asigna un nombre
    name: 'Org_logosAdmin',
    // Se indica el componente a usar ( Se importa al inicio de éste archivo )
    component: Org_logosAdmin
  },
  // Aquí configuro las rutas (url) para las acciones de los meses
  // Solo lo dejo declarado, hay que desarrollar los archivos
 {
    path: '/org_logos/org_logosAdmin/:action',
    name: 'ActionsOrg_logosAdmin',
    component: ActionsOrg_logosAdmin
  },
  {
    // :action - inica la accion
    // :id? - parámetro opcional
    path: '/org_logos/org_logosAdmin/:action/:id?',
    name: 'ActionsOrg_logosAdmin',
    component: ActionsOrg_logosAdmin
  },
    // ***********************Acercade*********************************
  // Aquí configuro las rutas (url) para las acciones de acercade
  // Solo lo dejo declarado, hay que desarrollar los archivos
  
  {
    // Se declara la url a utilizar
    path: '/acercade/acercadeAdmin',
    // Se le asigna un nombre
    name: 'acercadeAdmin',
    // Se indica el componente a usar ( Se importa al inicio de éste archivo )
    component: AcercadeAdmin
  },
  
  
  {
    path: '/acercade/acercadeAdmin/:action',
    name: 'ActionsAcercadeAdmin',
    component: ActionsAcercadeAdmin
  },
  {
    // :action - inica la accion
    // :id? - parámetro opcional
    path: '/acercade/acercadeAdmin/:action/:id?',
    name: 'ActionsAcercadeAdmin',
    component: ActionsAcercadeAdmin
  },
    // ***********************ServicioResp*********************************
  // Aquí configuro las rutas (url) para las acciones de acercade
  // Solo lo dejo declarado, hay que desarrollar los archivos
  {
    // Se declara la url a utilizar
    path: '/servicioresp/serviciorespAdmin',
    // Se le asigna un nombre
    name: 'serviciorespAdmin',
    // Se indica el componente a usar ( Se importa al inicio de éste archivo )
    component: ServicioRespAdmin
  },
  
  
  {
    path: '/servicioresp/serviciorespAdmin/:action',
    name: 'ActionsServicioRespAdmin',
    component: ActionsServicioRespAdmin
  },
  {
    // :action - inica la accion
    // :id? - parámetro opcional
    path: '/servicioresp/serviciorespAdmin/:action/:id?',
    name: 'ActionsServicioRespAdmin',
    component: ActionsServicioRespAdmin
  },

  
  // ***********************ConoceDerechos*********************************
  // Aquí se hace la llamada a la vista a utilizar para esta url
  {
    // Se declara la url a utilizar
    path: '/conocederechos/conocederechosAdmin',
    // Se le asigna un nombre
    name: 'conocederechosAdmin',
    // Se indica el componente a usar ( Se importa al inicio de éste archivo )
    component: ConoceDerechosAdmin
  },
  // Aquí configuro las rutas (url) para las acciones de la ConoceDerechos
  // Solo lo dejo declarado, hay que desarrollar los archivos
  {
    path: '/conocederechos/conocederechosAdmin/:action',
    name: 'ActionsConoceDerechosAdmin',
    component: ActionsConoceDerechosAdmin
  },
  {
    // :action - inica la accion
    // :id? - parámetro opcional
    path: '/conocederechos/conocederechosAdmin/:action/:id?',
    name: 'ActionsConoceDerechosAdmin',
    component: ActionsConoceDerechosAdmin
  },

// ***********************ConoceDerechosDoctos*********************************
  // Aquí se hace la llamada a la vista a utilizar para esta url
  {
    // Se declara la url a utilizar
    path: '/conocederechosdoctos/conocederechosdoctosAdmin',
    // Se le asigna un nombre
    name: 'conocederechosdoctosAdmin',
    // Se indica el componente a usar ( Se importa al inicio de éste archivo )
    component: ConoceDerechosDoctosAdmin
  },
  // Aquí configuro las rutas (url) para las acciones de la ConoceDerechosDoctos
  // Solo lo dejo declarado, hay que desarrollar los archivos
  {
    path: '/conocederechosdoctos/conocederechosdoctosAdmin/:action',
    name: 'ActionsConoceDerechosDoctosAdmin',
    component: ActionsConoceDerechosDoctosAdmin
  },
  {
    // :action - inica la accion
    // :id? - parámetro opcional
    path: '/conocederechosdoctos/conocederechosdoctosAdmin/:action/:id?',
    name: 'ActionsConoceDerechosDoctosAdmin',
    component: ActionsConoceDerechosDoctosAdmin
  },
  
  // ***********************principal en Cuidando el camino*********************************
  // Aquí se hace la llamada a la vista a utilizar para esta url
  {
    // Se declara la url a utilizar
    path: '/principal/principalAdmin',
    // Se le asigna un nombre
    name: 'principalAdmin',
    // Se indica el componente a usar ( Se importa al inicio de éste archivo )
    component: PrincipalAdmin
  },
  
  // Aquí configuro las rutas (url) para las acciones del catálogo de principal
  // Solo lo dejo declarado, hay que desarrollar los archivos
  {
    path: '/principal/principalAdmin/:action',
    name: 'ActionsPrincipalAdmin',
    component: ActionsPrincipalAdmin
  },
  {
    // :action - inica la accion
    // :id? - parámetro opcional
    path: '/principal/principalAdmin/:action/:id?',
    name: 'ActionsPrincipalAdmin',
    component: ActionsPrincipalAdmin
  },
  
  {
    path: '/recover',
    name: 'RecuperaciónPassword',
    component: Recover
  },
  {
    // :hash - parámetro
    path: '/cambio_password/:hash',
    name: 'CambioPassword',
    component: CambioPassword
  },
    // ***********************RegistroInf*********************************
  // Aquí configuro las rutas (url) para las acciones de acercade
  // Solo lo dejo declarado, hay que desarrollar los archivos
  {
    // Se declara la url a utilizar
    path: '/registroinf/registroinfAdmin',
    // Se le asigna un nombre
    name: 'registroinfAdmin',
    // Se indica el componente a usar ( Se importa al inicio de éste archivo )
    component: RegistroInfAdmin
  },
  
  
  {
    path: '/registroinf/registroinfAdmin/:action',
    name: 'ActionsRegistroInfAdmin',
    component: ActionsRegistroInfAdmin
  },
  {
    // :action - inica la accion
    // :id? - parámetro opcional
    path: '/registroinf/registroinfAdmin/:action/:id?',
    name: 'ActionsRegistroInfAdmin',
    component: ActionsRegistroInfAdmin
  },  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const secName = to.name?.toString()
  //document.title = `${ process.env.VUE_APP_TITLE } - ` + secName  emt TITULO DE LA PAGINA
  document.title = `Cuidando el camino - ` + secName
  if (to.params.title) {
//    document.title = `${ process.env.VUE_APP_TITLE } - ${ secName } - ${ to.params.title }`  TITULO DE LA PÁGINA
    document.title = `Cuidando el camino - ${ secName } - ${ to.params.title }`
  }
  next()
})

export default router
