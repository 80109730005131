/* Regresa todos los datos de la tabla 'cat_menu_pagina' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getCat_menu_paginaById = async( id ) => {
    const promiseArr = [
        simejuvApi.get(`/cat_menu_pagina/${ id }`)
    ]

    const [ cat_menu_pagina ] = await Promise.all( promiseArr )

    return cat_menu_pagina.data
}

export default getCat_menu_paginaById