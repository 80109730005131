<template>
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="/"><i class="glyphicon glyphicon glyphicon-home"></i></a></li>
        <li class="breadcrumb-item"><a href="/">CAMINOS SEGUROS</a></li>
        <li class="breadcrumb-item active" aria-current="page"><a href="#">Iniciar sesión</a></li>
    </ol>
    <h2>Iniciar sesión</h2>
    <hr class="red mb-5">
    <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6 col-sm-12">
            <div class="panel panel-success">
                <div class="panel-heading text-center">
                    <h3 class="panel-title">Acceso</h3>
                </div>
                <div class="panel-body">
                    <form role="form" @submit.prevent="login">
                        <div class="form-group row has-success has-feedback">
                            <label for="email">Correo electrónico:</label>
                            <div class="col-sm-12">
                                <div class="input-group flex-nowrap">
                                    <span class="input-group-addon">@</span>
                                    <input type="text" id="email" v-model="textValue" class="form-control" placeholder="Ingresa tu correo electrónico" aria-label="Ingresa tu correo electrónico" aria-describedby="inputEmail">
                                </div>
                            </div>
                        </div>
                        <div class="form-group row has-success has-feedback">
                            <label for="password">Contraseña:</label>
                            <div class="col-sm-12">
                                <div class="input-group flex-nowrap">
                                    <span class="input-group-addon"><i class="glyphicon glyphicon-asterisk"></i></span>
                                    <input type="password" id="password" v-model="passValue" class="form-control" placeholder="Ingresa tu contraseña" aria-label="Ingresa tu contraseña" aria-describedby="inputPaswword">
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <p class="note">*Campos obligatorios</p>
                        </div>
                        <div class="form-group row">
                            <div class="col-12 text-right">
                                <input type="submit" class="btn btn-primary active btnCond" value="Ingresar" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 text-center">
                                <router-link to="/recover" class="recoverPass">Recuperar contraseña</router-link>
                            </div>
                        </div>
                        <div class="alert alert-danger" id="alertError" v-if="msgError">{{ msgError }}</div>
                    </form>
                </div>
            </div>
        </div>
        <div class="col-md-3"></div>
    </div>
</template>

<script>
import baseApi from '@/api/baseApi'
import getAcceso from '@/helpers/getAcceso'
import sha1 from 'sha1'
import charsService from '@/services/CharsService';

export default {
    data() {
        return {
            textValue: null,
            passValue: null,
            msgError: ''
        }
    },
    methods: {
        async getToken() {
            const mTok = await baseApi.get('/makeToken.php')
            // console.log( mTok )
            return mTok
        },
        async login() {
            await getAcceso( document.querySelector('#email').value )
            .then( res => {
                if ( res.length < 1 ) {
                    this.msgError = 'Usuario/Contraseña inválidos!'
                } else {
                    if( res[0].inactivo > 0 ){
                        this.msgError = 'Usuario inactivo. Favor de contactar al administrador del sistema.'
                    } else {
                        // console.log( 'Pass => ', res[0].password );
                        if ( res[0].password === sha1(document.querySelector('#password').value) ){
                            const token = this.getToken()
                            token.then( r => {
                                localStorage.setItem('token', r.data)

                                localStorage.setItem('id', charsService.encode(res[0].id))
                                localStorage.setItem('nombre', charsService.encode(res[0].nombre))
                                localStorage.setItem('tipo_usuario', charsService.encode(res[0].tipo_usuario))
                                localStorage.setItem('email', charsService.encode(res[0].email))
                                localStorage.setItem('dependencia', charsService.encode(res[0].id_dependencia))

                                window.location.href = './adminSec'
                            }, e => {
                                console.log( 'Error!!! Responde: ' + e )
                            })
                        } else {
                            this.msgError = 'Usuario/Contraseña inválidos!!!'
                        }
                    }
                }
            })
            .catch( err => {
                if(err){    //EMT julio
                    this.msgError = 'Ocurrió un error!'
                }    
            })
        }
    },
    mounted() {
        if ( charsService.decode(localStorage.getItem('usuario')) === 'admin' || charsService.decode(localStorage.getItem('usuario')) === 'contenido' || charsService.decode(localStorage.getItem('usuario')) === 'enlace' ) {
            this.$router.push('/adminSec')
        }
    }
}
</script>

<style scoped>
    form {
        padding-top: 0;
    }

    .card {
        margin: 0 auto;
    }

    .card-header {
        background-color: #7F00FF;     /* Antes AD00E8 */
        color: #FFF;
    }

    .card-body {
        background-color: #FFF;
    }

    .content {
        padding: 20px;
    }

    .note {
        width: 100%;
        font-size: small;
        text-align: right;
    }

    .input-group {
        display: flex;
    }

    .panel-center {
        margin: auto 0;
    }

    .panel-heading {
        background-color: #7F00FF;          /*  Antes 235B4E   */
        color: #FFF;
    }

    .panel-body {
        padding: 30px;
    }

    .input-group-addon {
        width: 40px;
        background-color: #D9D9D9;
    }
 
    

    .recoverPass {
        font-size: medium;
        color: #AD00E8;                 /* antes 8D2042 */
    }
    
</style>