import { ref } from 'vue'
//export const url = ref( 'http://localhost' )
//export const ubApi = ref( 'http://localhost/ubApi' )
//export const icons = ref( 'http://localhost/icons' ) 

//export const url = ref( 'https://caminos-segurosv2.rmsh.club' )
//export const ubApi = ref( 'https://caminos-segurosv2.rmsh.club/ubApi' )
//export const icons = ref( 'https://caminos-segurosv2.rmsh.club/icons' ) 

export const url = ref( 'https://cc.rmsh.club' )
export const ubApi = ref( 'https://cc.rmsh.club/ubApi' )
export const icons = ref( 'https://cc.rmsh.club/icons' ) 
