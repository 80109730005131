<template>
    <div class="mainDiv">
        <Navbar />
        <div class="mt-5 container principal text-left">
          <CambioPassword />
        </div>
    </div>
</template>

<script>
import Navbar from '@/components/Common/Navbar'
import CambioPassword from '@/components/Login/CambioPassword'

export default {
  components: {
    Navbar,
    CambioPassword
  },
  mounted() {
    window.scrollTo(0, 0)
  }
}
</script>