<template>
    <div class="mainDiv">
        <Navbar />
        <div class="mt-5 container principal text-left">
          <Recover />
        </div>
    </div>
</template>

<script>
import Navbar from '@/components/Common/Navbar'
import Recover from '@/components/Login/Recover'

export default {
  components: {
    Navbar,
    Recover
  },
  mounted() {
    window.scrollTo(0, 0)
  }
}
</script>