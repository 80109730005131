/* Regresa id y nombre corto de todos los registros de la tabla 'cat_clase_ind' en la base de datos */

import simejuvApi from '../api/simejuvApi'

/* 
* @param incTodas: false: no regresa NO-SECTORIZADA y TODAS (default)
*                  true: regresa todos los registros
*/
const getCatsNc = async( filter) => {

    const promiseArr = [
        simejuvApi.get(`${ filter }`)
    ]

    const [ cla_inds ] = await Promise.all( promiseArr )

    return cla_inds.data
}

export default getCatsNc