/* Regresa todos los datos de la tabla 'cat_documentos' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getCat_documentosById = async( id ) => {
    const promiseArr = [
        simejuvApi.get(`/cat_documentos/${ id }`)
    ]

    const [ cat_documentos ] = await Promise.all( promiseArr )

    return cat_documentos.data
}

export default getCat_documentosById