/* Regresa todos los datos del usuario con el id enviado de la tabla 'informe' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getInformesById = async( id ) => {
    const promiseArr = [
        simejuvApi.get(`/informes/${ id }`)
    ]

    const [ informe ] = await Promise.all( promiseArr )

    return informe.data
}

export default getInformesById