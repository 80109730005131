<template>
    
        <div class="row bg-light " style="">
            <div class="col-lg-12" style="background-color:#ffffff">
                <div class="col-md-12"  style="">
                        <div class="article-body" >
                            <p class="card-text text-center" style="" v-html="this.contenido_organizacioones"></p>
                        </div>
                </div>
                
                <div class="col-lg-12">
                    <br>
                </div>

                <div class="col-lg-12" style="background-color:#ffffff">
                    <div class="col-md-3" v-for="(h) in arrInformes" :key="h.id">
                        <div class="">
                            <button class="btn"  style="height: 220px;"> 
                                <div class="carousel-caption">    
                                    <a :href="h.url_organizacion" target="_blank" > 
                                        <img style="height: 95%; object-fit: cover;" :src= "h.ImagenUrl"  alt="Logo"  >
                                    </a>    
                                </div> 
                            </button>
                        </div>
                       
                    </div>
                </div>

                            





    
            </div>
        </div>
</template>

<script>
import getAcercadeById from '@/helpers/getAcercadeById'
import getOrg_logos from '@/helpers/getOrg_logos'
import { ubApi } from '@/api/baseUrl'
import { url } from '@/api/baseUrl'
//import charsService from '@/services/CharsService';

export default {
    //url_imagen:'',
    data() {
        return { 
            ubApi: ubApi.value,
            url: url.value,
            contenido_organizacioones: '',
            arrInformes: [],
        }
    },
    methods: {
        async getCat_Acercade() {
            const datos = await getAcercadeById(  localStorage.getItem("Nidioma") )
            this.contenido_organizacioones = datos.contenido_organizacioones;
            return
        },

        async getAllEventosxMun() {
          const informesArr = await getOrg_logos()
          const arrShow = []

          for (let i=0; i<informesArr.length; i++) {

                const ImagenUrl =  this.url + '/uploads/' + `${ informesArr[i].imagen_url }`
                const url_organizacion =  `${ informesArr[i].url_organizacion }`

                arrShow.push({
                        ImagenUrl,
                        url_organizacion,
                })
          }

          return arrShow
        },

    },
    async mounted() {
        await this.getCat_Acercade();
        //this.datosBienvenida = await this.getConoceDerechos()
        this.arrInformes = await this.getAllEventosxMun()
        window.scrollTo(0, 0)
    }
}
</script>
<style scoped>
    input {
        padding: 5px 40px;
        margin: 25px 0 50px;
        text-align: justify;
    }
    .article-body {
        padding: 20px 0px 0px 20px;
        
        text-align: justify;
    }


/*   seccion de sombreado de la Imagen */
    .carousel-caption {
        color: #ffffff;
        background-color:#ffffff;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 100;
        top: 0px;
        left: 0px;

        
    }
    
    d-block {
        width: 100%;
    }

    #mainCarousel {
        margin: 70px 0;
    }



</style>