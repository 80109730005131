<template>
    <div class="row">
        <!-- Panel Dashboard -->
        
        <div class="col-lg-12">

            <div class="card-header" style="padding: 0px 0px 0px 15px;">
                    <span><p class="card-text" style="color:#542EA5;" v-html="this.descripcion_dashboard"></p></span>
            </div>

            <div>
                <div  style="padding: 5px 20px 5px 15px; box-shadow: 5px 5px 15px gray;">
                    <iframe class="iframe-config" :src="url_dashboard" width="100%" frameborder="0" marginheight="0" allow="fullscreen"></iframe>
                </div> 
            </div>
            <br><br><br>
        </div>    
    </div>    

</template>

<script>
//import axios from 'axios'
//import moment from 'moment'
import getCat_principalById from '@/helpers/getCat_principalById'
export default {
     
    data() {
        
        return {
            descripcion_dashboard: '',
            url_dashboard: '',
            //url_mapa: 'https://cuidandoelcamino.maps.arcgis.com/apps/dashboards/a63889c349854dd6b06afd02653f1aac',
        }
    },
    methods: {


        async getCat_principal() {
            const datos = await getCat_principalById(  localStorage.getItem("Nidioma") )
            this.descripcion_dashboard = datos.descripcion_dashboard;
            this.url_dashboard = datos.url_dashboard;
            return
        }
    },
    async mounted() {
        await this.getCat_principal();
    }    
}
</script>

<style scoped>

/* APARTADO RESPONSIVO */
          @media (min-width:320px)  { 
            /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
                .iframe-config{
                    height: 550px;
                }
           }
          @media (min-width:480px)  { 
          /* smartphones, Android phones, landscape iPhone */ 
                .iframe-config{
                    height: 500px;
                }
          }
          @media (min-width:600px)  { 
          /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */ 
                .iframe-config{
                    height: 500px;
                }
          }
          @media (min-width:801px)  { 
          /* tablet, landscape iPad, lo-res laptops ands desktops */ 
                .iframe-config{
                    height: 500px;
                }
          }
          @media (min-width:1025px) { 
          /* big landscape tablets, laptops, and desktops */ 
                .iframe-config{
                    height: 500px;
                }
          }
          @media (min-width:1281px) { 
          /* hi-res laptops and desktops */
                .iframe-config{
                    height: 800px;
                }
          } 


</style>