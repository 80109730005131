class CharsService {
    encode( chars ) {
        return window.btoa(encodeURIComponent( 'Éncödê___' + chars + '___ch~rs->' ));
    }

    decode( chars, typeOf = 'number' ) {
        const setChars = decodeURIComponent(window.atob( chars ));
        const splitChars = setChars.split('___');
        if (typeOf === 'number')
            return parseInt(splitChars[1]);
        else
            return splitChars[1];
    }
}

export default new CharsService()