<template>
    <h4 class="mt-0">Actualizar información de usuario</h4>
    <hr>
    <div class="row mb-5">
        <div class="col-md-12">
            <form>
                <div class="form-group row">
                    <label class="col-md-4" to="nombre">Nombre:</label>
                    <div class="col-md-8">
                        <input type="hidden" id="idUsr" name="idUsr" v-model="form.id" />
                        <input type="text" class="form-control" id="nombre" name="nombre" placeholder="Nombre" v-model="form.nombre" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="a_paterno">Apellido Paterno:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" id="a_paterno" name="a_paterno" placeholder="Apellido Paterno" v-model="form.a_paterno" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="a_materno">Apellido Materno:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" id="a_materno" name="a_materno" placeholder="Apellido Materno" v-model="form.a_materno" />
                    </div>
                </div>

                <hr>
                <!--
                <div class="form-group row">
                    <label class="col-md-4" to="dependencia">Institución o dependencia:</label>
                    <div class="col-md-8">
                        <select class="form-control" id="dependencia" name="dependencia" v-model="form.dependencia">
                            <option v-for="e in depsArr" :key="e.id" :value="e.id">{{ e.id }} {{ e.nom_corto_dependencia }}</option>
                        </select>
                    </div>
                </div>
                -->
                <div class="form-group row">
                    <label class="col-md-4" to="puesto">Puesto:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" id="puesto" name="puesto" placeholder="Puesto" v-model="form.puesto" />
                    </div>
                </div>

                <hr>

                <div class="form-group row">
                    <label class="col-md-4" to="email">Correo electrónico:</label>
                    <div class="col-md-8">
                        <input type="email" class="form-control" id="email" name="email" placeholder="Correo electrónico" v-model="form.email" disabled />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="telefono">Teléfono:</label>
                    <div class="col-md-8">
                        <input type="tel" class="form-control" id="telefono" name="telefono" placeholder="Teléfono" v-model="form.telefono" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="extension">Extensión:</label>
                    <div class="col-md-8">
                        <input type="tel" class="form-control" id="extension" name="extension" placeholder="Extensión" v-model="form.extension" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="tel_celular">Celular:</label>
                    <div class="col-md-8">
                        <input type="tel" class="form-control" id="tel_celular" name="tel_celular" placeholder="Celular" v-model="form.tel_celular" />
                    </div>
                </div>

                <hr>

                <div class="form-group row">
                    <div class="col-md-6 text-left">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateUser" /> -->
                        <input type="button" class="btn btn-default btn-sm active" id="regresar" name="regresar" value="Regresar" @click="goBack" />
                    </div>
                    <div class="col-md-6 text-right">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateUser" /> -->
                        <input type="button" class="btn btn-primary btn-sm active" id="guardar" name="guardar" value="Guardar" @click="updateUser" />
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
// import axios from 'axios'
import getUsersById from '@/helpers/getUsersById'
//import getDependenciaNC from '@/helpers/getDependenciaNC'
//import getDependenciasNc from '@/helpers/getDependenciasNc'
import updateData from '@/helpers/updateData'
import charsService from '@/services/CharsService';

export default {
    data() {
        return {
            id: '',
            data: [],
            datos: [],
            dependencia: '',
            dependenciaNc: '',
            form: {
                id: '',
                nombre: '',
                a_paterno: '',
                a_materno: '',
                dependencia: '',
                puesto: '',
                email: '',
                telefono: '',
                extension: '',
                tel_celular: '',
            },
            //depsArr: [],
        }
    },
    methods: {
        async getUserData( id ) {
            const userArr = await getUsersById( id )
            
            return userArr[0].usuario
        },

        async setData( id ) {
            const datos = await getUsersById( id )

            this.form.id = datos.id
            this.form.nombre = datos.nombre
            this.form.a_paterno = datos.a_paterno
            this.form.a_materno = datos.a_materno
            this.form.dependencia = datos.id_dependencia
           // this.dependenciaNc = await getDependenciaNC(datos.id_dependencia)
            this.form.puesto = datos.puesto
            this.form.email = datos.email
            this.form.telefono = datos.telefono
            this.form.extension = datos.extension
            this.form.tel_celular = datos.tel_celular
        },

        async updateUser() {
            const dataForm = {
                nombre: this.form.nombre,
                a_paterno: this.form.a_paterno,
                a_materno: this.form.a_materno,
                id_dependencia: this.form.dependencia,
                dependenciaNc: this.dependenciaNc,
                puesto: this.form.puesto,
                telefono: this.form.telefono,
                extension: this.form.extension,
                tel_celular: this.form.tel_celular,
            };

            const jsonData = JSON.parse(JSON.stringify( dataForm ));

            const updAcc = await updateData('usuarios', this.form.id, jsonData)

            if ( updAcc[0].res == 1 ){
                this.$notify({
                    title: 'Correcto', text: 'Se actualizó correctamente la información', type: 'success', duration: 5000, speed: 1000
                });
            } else {
                this.$notify({
                    title: 'Error', text: 'Ocurrió un error. Verifica los datos', type: 'error'
                });
            }
        },

        goBack() {
            this.$router.go(-1)
        },

        //async getDependencias() {
        //    const deps = await getDependenciasNc()
        //    return deps
        //},

    },
    async mounted() {
        this.setData( charsService.decode(this.$route.params.id) )
        //this.depsArr = await this.getDependencias()

        window.scrollTo(0, 0)
    }
}
</script>

<style scoped>
    form {
        font-size: medium;
    }

    input, select {
        font-size: medium;
    }

    input[type=button] {
        font-size: 14px;
    }
</style>