<template>
    <h4 class="mt-0">Administración avanzada</h4>
    <hr>
    <p>Para asignar una Acción Puntual al usuario seleccionarla en la columna de la izquierda y moverla hacia la derecha. Para desasignarla hacer el mismo proceso en sentido contrario.</p>
    <br>

    <div class="row">
        <div class="col-md-12 btnBack">
            <input type="button" class="btn btn-default btn-sm active" id="regresar" name="regresar" value="Regresar" @click="goBack" />
        </div>
    </div>
    <div class="row mb-5">
        <div class="col-sm-12">
            <strong>Año de asignación:</strong>
        </div>
        <div class="col-sm-2">
            <select name="anioAsigna" id="anioAsigna" v-model="anioAsigna" class="unique">
                <option value="" disabled>-Seleccione-</option>
                <option value="2022">2022</option>
                <option value="2023">2023</option>
                <option value="2024">2024</option>
            </select>
        </div>
    </div>
    <div class="row mb-5">
        <div class="col-sm-5">
            <h5>Acciones disponibles</h5>
            <select multiple v-model="disponiblesSelected" @dblclick="asignar">
                <option v-for="accion in disponibles" :key="accion.id">
                    {{ accion }}
                </option>
            </select>
        </div>

        <div class="col-sm-2 middle">
            <button @click="asignar" class="btn btn-default btn-xs active" title="Asignar accion(es) seleccionada(s)" >=&gt;</button><br>
            <br><button @click="hacerDisponible" class="btn btn-default btn-xs active" title="Quitar accion(es) seleccionada(s) de las asignadas">&lt;=</button>
        </div>
        
        <div class="col-sm-5">
            <h5>Acciones asignadas</h5>
            <select multiple v-model="asignadasSelected" @dblclick="hacerDisponible" id="asignadas">
                <option v-for="accion in asignadas" :key="accion.id">
                    {{ accion }}
                </option>
            </select>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 btnSubmit">
            <input type="button" value="Guardar asignaciones" @click="guardarAsignaciones" class="btn btn-primary btn-sm active">
        </div>
    </div>
</template>

<script>
// import axios from 'axios'
import getAccesoByUser from '@/helpers/getAccesoByUser'
//import getAccionesPuntuales from '@/helpers/getAccionesPuntuales'
//import getResponsables from '@/helpers/getResponsables'
import getUsersById from '@/helpers/getUsersById'
import updateDataByIdCol from '@/helpers/updateDataByIdCol'
import charsService from '@/services/CharsService'

export default {
    data() {
        return {
            nombreUsr: '',
            anioAsigna: 2022,
            disponiblesSelected: [],
            disponibles: [],
            asignadasSelected: [],
            asignadas: [],
            arrAccionesDependencia: [],
            arrAccionesByCode: []
        }
    },
    methods: {
        async getAccesoData( id ) {
            const accesoArr = await getAccesoByUser( id )
            
            return accesoArr[0]
        },

        async getUserData( id ) {
            const UserArr = await getUsersById( id )

            return UserArr
        },

        async getAcciones() {
/*
            const AccionesArr = await getResponsables()

            AccionesArr.forEach(accion => {
                const dep = accion.iddependencia
                const acc = accion.idaccion_puntual

                if (dep < 100) {
                    if ( !this.arrAccionesDependencia[dep] ) {
                        this.arrAccionesDependencia[dep] = acc.toString()
                    } else {
                        this.arrAccionesDependencia[dep] += ',' + acc.toString()
                    }
                }
            })
*/            
        },

//        async setAcciones( id ) {
//            const dataUser = await this.getUserData( id )
//            const depUser = dataUser.id_dependencia
           // const acciones = await getAccionesPuntuales()
//            const accesoData = await this.getAccesoData( id )
//            const accionesAsignadas = accesoData.acciones_usuario_2022
/*
            let asignDef = ''

            if ( !accionesAsignadas ) {
                asignDef = this.arrAccionesDependencia[ depUser ].split(',')
            } else {
                asignDef = accionesAsignadas.split(',')
            }
*/            
/*
            acciones.forEach(accion => {
                if ( asignDef.includes( accion.id.toString() ) ) {
                    this.asignadas.push( accion.codigo_accion_puntual )
                } else {
                    this.disponibles.push( accion.codigo_accion_puntual )
                }

                this.arrAccionesByCode[accion.codigo_accion_puntual] = accion.id
            })
*/            
//        },

        hacerDisponible() {
            if( !this.asignadasSelected.length ) return
            for( let i=this.asignadasSelected.length; i>0; i-- ) {
                let idx = this.asignadas.indexOf( this.asignadasSelected[i-1] )
                this.asignadas.splice( idx, 1 )
                this.disponibles.push( this.asignadasSelected[i-1] )
                this.asignadasSelected.pop()
            }
        },
    
        asignar() {
            if( !this.disponiblesSelected.length ) return
            // console.log( 'asignar', this.disponiblesSelected )
            for( let i=this.disponiblesSelected.length; i>0; i-- ) {
                let idx = this.disponibles.indexOf( this.disponiblesSelected[i-1] )
                this.disponibles.splice( idx, 1 )
                this.asignadas.push( this.disponiblesSelected[i-1] )
                this.disponiblesSelected.pop();
            }
        },

        async guardarAsignaciones() {
            const boxVal = document.querySelector("#asignadas")
            const accesoData = await this.getAccesoData( charsService.decode(this.$route.params.id) )
            const idAcc = accesoData.id

            const selectedValuesCodes = [].filter
                .call(boxVal.options, option => option)
                .map(option => option.text);
            let selectedIds = '';

            selectedValuesCodes.sort()

            selectedValuesCodes.forEach(code => {
                selectedIds += this.arrAccionesByCode[ code ] + ','
            })
            
            selectedIds = (selectedIds === '')? null : selectedIds.slice(0, -1);

            const jsonData = JSON.parse(JSON.stringify( { acciones_usuario_2022: selectedIds } ))
            const updateItem = await updateDataByIdCol( 'acceso', idAcc, jsonData )
            if ( updateItem[0].res == 1 ) {
                this.$notify({
                    title: 'Correcto', text: 'Se actualizó correctamente la información', type: 'success', duration: 5000, speed: 1000
                });
            }
        },

        goBack() {
            this.$router.go(-1)
        },
    },
    async mounted() {
        //const id = charsService.decode(this.$route.params.id)
        
       // this.setAcciones( id )
        this.getAcciones()
        

        if (this.tipoUsr === 3)
            this.getAccionesArr()
        
        window.scrollTo(0, 0)
    }
}
</script>

<style scoped>
    form {
        font-size: medium;
    }

    button {
        font-size: 14px;
        font-weight: bold;
        width: 100px;
    }

    input, select {
        font-size: medium;
    }

    input[type=button] {
        font-size: 14px;
    }

    label {
        margin-left: 10px;
    }

    .inputDesc {
        white-space: nowrap;
    }

    .panel {
        min-width: 940px;
    }

    select {
        height: 200px;
        width: 100%;
        font-size: 14px;
    }

    .unique {
        height: 30px;
    }

    .middle {
        text-align: center;
        padding-top: 50px;
    }

    .btnSubmit {
        text-align: center;
        margin-bottom: 20px;
    }

    .btnBack {
        text-align: right;
        margin-bottom: 20px;
    }
</style>