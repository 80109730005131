<template>
    <h4 class="mt-0">Agregar un usuario</h4>
    <hr>
    <div class="row mb-5">
        <div class="col-md-12">
            <form>
                <div class="form-group row">
                    <label class="col-md-4" to="nombre">Nombre:</label>
                    <div class="col-md-8">
                        <input type="hidden" id="idUsr" name="idUsr" v-model="form.id" />
                        <input type="text" class="form-control" :class="{ validated: noValid.nombre }" id="nombre" name="nombre" placeholder="Nombre" v-model="form.nombre" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="a_paterno">Apellido Paterno:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" :class="{ validated: noValid.a_paterno }" id="a_paterno" name="a_paterno" placeholder="Apellido Paterno" v-model="form.a_paterno" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="a_materno">Apellido Materno:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" id="a_materno" name="a_materno" placeholder="Apellido Materno" v-model="form.a_materno" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="email">Correo electrónico:</label>
                    <div class="col-md-8">
                        <input type="email" class="form-control" :class="{ validated: noValid.email }" id="email" name="email" placeholder="Correo electrónico" v-model="form.email" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="email">Contraseña:</label>
                    <div class="col-md-8">
                        <input type="password" class="form-control" :class="{ validated: noValid.password }" id="password" name="password" placeholder="Contraseña" v-model="form.password" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="email">Verificar contraseña:</label>
                    <div class="col-md-8">
                        <input type="password" class="form-control" :class="{ validated:(form.repassword !== form.password)||noValid.repassword }" id="repassword" name="repassword" placeholder="Verificar contraseña" v-model="form.repassword" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="tipoUsr">Tipo de usuario:</label>
                    <div class="col-md-8">
                        <select class="form-control" id="tipoUsr" name="tipoUsr" v-model="form.tipoUsr" :class="{ validated: noValid.tipoUsr }">
                            <option value="" disabled selected>[Seleccione]</option>
                            <option v-for="d in tUsrArr" :key="d.id" :value="d.id">{{ d.descripcion }}</option>
                        </select>
                    </div>
                </div>

                <hr>
<!--
                <div class="form-group row">
                    <label class="col-md-4" to="dependencia">Institución o dependencia:</label>
                    <div class="col-md-8">
                        <select class="form-control" id="dependencia" name="dependencia" v-model="form.dependencia" :class="{ validated: noValid.dependencia }">
                            <option value="" disabled selected>[Seleccione]</option>
                            <option v-for="e in depsArr" :key="e.id" :value="e.id">{{ e.nom_corto_dependencia }}</option>
                        </select>
                    </div>
                </div>
-->                
                <div class="form-group row">
                    <label class="col-md-4" to="puesto">Puesto:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control" id="puesto" name="puesto" placeholder="Puesto" v-model="form.puesto" />
                    </div>
                </div>

                <hr>

                <div class="form-group row">
                    <label class="col-md-4" to="telefono">Teléfono:</label>
                    <div class="col-md-8">
                        <input type="tel" class="form-control" id="telefono" name="telefono" placeholder="Teléfono" v-model="form.telefono" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="extension">Extensión:</label>
                    <div class="col-md-8">
                        <input type="tel" class="form-control" id="extension" name="extension" placeholder="Extensión" v-model="form.extension" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="tel_celular">Celular:</label>
                    <div class="col-md-8">
                        <input type="tel" class="form-control" id="tel_celular" name="tel_celular" placeholder="Celular" v-model="form.tel_celular" />
                    </div>
                </div>

                <hr>

                <div class="form-group row">
                    <div class="col-md-6 text-left">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateUser" /> -->
                        <input type="button" class="btn btn-default active btn-sm" id="regresar" name="regresar" value="Regresar" @click="goBack" />
                    </div>
                    <div class="col-md-6 text-right">
                        <!-- <input type="submit" class="btn btn-primary active" id="guardar" name="guardar" value="Guardar" @click="updateUser" /> -->
                        <input type="reset" class="btn btn-default btn-sm active" id="reset" name="reset" value="Otro usuario" @click="clearSelect" :disabled="!added" />
                        <input type="button" class="btn btn-primary btn-sm active" id="crear" name="crear" value="Crear" @click="addUser" :disabled="added" />
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
//import getDependenciasNc from '@/helpers/getDependenciasNc'
import addData from '@/helpers/addData'
import getDataByAny from '@/helpers/getDataByAny'
import getTiposUsuario from '@/helpers/getTiposUsuario'
//import charsService from '@/services/CharsService'
import sendMailService from '@/services/SendMailService'
import sha1 from 'sha1'

export default {
    data() {
        return {
            form: {
                nombre: '',
                a_paterno: '',
                a_materno: '',
                email: '',
                password: '',
                repassword: '',
                tipoUsr: '',
                dependencia: '',
                puesto: '',
                telefono: '',
                extension: '',
                tel_celular: ''
            },
            noValid: {
                nombre: false,
                a_paterno: false,
                email: false,
                password: false,
                repassword: false,
                dependencia: false,
                tipoUsr: false,
            },
            depsArr: [],
            tUsrArr: [],
            passDif: false,
            added: false
        }
    },
    methods: {
        /*
        async getDependencias() {
            const deps = await getDependenciasNc()
            return deps
        },
        */
        async getTiposUsuario() {
            const tUsr = await getTiposUsuario()
            return tUsr
        },
        goBack() {
            this.$router.go(-1)
        },
        clearSelect() {
            this.form.nombre = ''
            this.form.a_paterno = ''
            this.form.a_materno = ''
            this.form.email = ''
            this.form.password = ''
            this.form.repassword = ''
            setTimeout(function() { document.getElementById('tipoUsr').getElementsByTagName('option')[0].selected = 'selected' }, 1);
            document.getElementById('tipoUsr').getElementsByTagName('option')[0].selected = 'selected'
            //setTimeout(function() { document.getElementById('dependencia').getElementsByTagName('option')[0].selected = 'selected' }, 1);
            //document.getElementById('dependencia').getElementsByTagName('option')[0].selected = 'selected'
            this.form.puesto = ''
            this.form.telefono = ''
            this.form.extension = ''
            this.form.tel_celular = ''
            this.added = false
        },
        async addUser() {
            this.noValid.nombre = ( this.form.nombre )? false : true
            this.noValid.a_paterno = ( this.form.a_paterno )? false : true
            this.noValid.email = ( this.form.email )? false : true
            this.noValid.password = ( this.form.password )? false : true
            this.noValid.repassword = ( this.form.repassword )? false : true
           // this.noValid.dependencia = ( this.form.dependencia )? false : true
            this.noValid.tipoUsr = ( this.form.tipoUsr )? false : true

            const valEmail = await getDataByAny( 'v_acceso', 'email', this.form.email );
            
            // Valido si existe el correo ya registrado
            if ( !valEmail ){

                // Valido si los campos que son requeridos contienen información
                if ( !this.noValid.nombre && !this.noValid.a_paterno && !this.noValid.email && !this.noValid.password && !this.noValid.repassword && !this.noValid.dependencia ) {
                    const dataForm = {
                        nombre: this.form.nombre,
                        a_paterno: this.form.a_paterno,
                        a_materno: this.form.a_materno,
                        email: this.form.email,
                        //id_dependencia: this.form.dependencia,
                        puesto: this.form.puesto,
                        telefono: this.form.telefono,
                        extension: this.form.extension,
                        tel_celular: this.form.tel_celular,
                    }

                    // Valido si el formato de correo es correcto
                    const emailVal = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9\\-])+.)+([a-zA-Z0-9]{2,4})+$/
                    if ( !emailVal.test( this.form.email ) ) {
                        this.$notify({
                            title: 'Error', text: 'El <strong>Correo electrónico</strong> ingresado no es válido, favor de verificarlo', type: 'error'
                        })
                        this.noValid.email = true

                        return
                    }

                    // Valido si el password cumple la longitud mínima requerida
                    if ( this.form.password.length < 8 ) {
                        this.$notify({
                            title: 'Error!', text: 'La contraseña nueva debe contener por lo menos 8 caracteres', type: 'error', duration: 5000, speed: 1000
                        })
                        this.noValid.password = true

                        return
                    }

                    // Valido si el password cumple el formato requerido
                    const specialChars = /[(@!#$%^&*)(+=._-]{1,}/
                    if ( !/[a-z]/.test(this.form.password) || !/[A-Z]/.test(this.form.password) || !/[0-9]/.test(this.form.password) || !specialChars.test(this.form.password) ) {
                        this.$notify({
                            title: 'Error!', text: 'La contraseña nueva debe contener por lo menos una mayúscula, una minúscula, un número y un caracter especial', type: 'error', duration: 5000, speed: 1000
                        })
                        this.noValid.password = true

                        return
                    }

                    // Valido si la confirmación del password coincide con el original
                    if ( this.form.password !== this.form.repassword ) {
                        this.$notify({
                            title: 'Error!', text: 'La contraseña y su confirmación deben ser iguales', type: 'error', duration: 5000, speed: 1000
                        })
                        this.noValid.repassword = true

                        return
                    }


                    const jsonData = JSON.parse(JSON.stringify( dataForm ))
                    // Inserto la información en la tabla de usuarios
                    const addUser = await addData('usuarios', jsonData)

                    if ( addUser[0].res.status === 200 ) {
                        const dataPass = {
                            id_usuarios: addUser[0].res.data,
                            password: sha1(this.form.password),
                            tipo_usuario: this.form.tipoUsr
                        }

                        const jsonPass = JSON.parse(JSON.stringify( dataPass ))
                        // Inserto la información en la tabla de acceso
                        const addPass = await addData('acceso', jsonPass)

                        if ( addPass[0].res.status === 200 ){
                            this.$notify({
                                title: 'Correcto', text: 'Se insertó correctamente la información', type: 'success', duration: 5000, speed: 1000
                            });
                            this.added = true

//                            const envio = await sendMailService.newUser(this.form.nombre, this.form.a_paterno, this.form.email, this.form.password)
                            await sendMailService.newUser(this.form.nombre, this.form.a_paterno, this.form.email, this.form.password)
                        } else {
                            this.$notify({
                                title: 'Error', text: 'Ocurrió un error. Verifica los datos', type: 'error'
                            });
                        }
                    } else {
                        this.$notify({
                            title: 'Error', text: 'Ocurrió un error. Verifica los datos', type: 'error'
                        });
                    }
                } else {
                    this.$notify({
                        title: 'Error', text: 'El formulario requiere atención en uno o varios campos. Favor de atenderlo', type: 'error'
                    });
                }
            } else {
                this.$notify({
                    title: 'Error', text: 'Ya existe un usuario registrado con ese correo electrónico. Verifica los datos', type: 'error'
                });
            }
        }
    },
    async mounted() {
        //this.depsArr = await this.getDependencias()
        this.tUsrArr = await this.getTiposUsuario()
    }
}
</script>

<style scoped>
    form, input, select {
        font-size: medium;
    }

    .validated {
        border: 3px solid #dc3545;
    }
</style>