/* Regresa todos los registros de la tabla 'v_conoce_derechos_idioma' en la base de datos */

// Esta linea se queda igual siempre (base url api)
import simejuvApi from '../api/simejuvApi'

// Se nombra la funcion
const getVconoce_derechos_idioma = async() => {
    const promiseArr = [
        // Se modifica de acuerdo a la búsqueda en la tabla
        simejuvApi.get(`/v_conoce_derechos_idioma?transform=1&filter=borrar,lt,1`)
    ]

    // Cambiar el nombre de la variable contra los datos a mostrar
    const [ vconocederechosidioma ] = await Promise.all( promiseArr )

    // Cambiar la primer variable a como se definió arriba
    // Cambiar la ultima variable a como se llama la tabla en la base de datos
    return vconocederechosidioma.data.v_conoce_derechos_idioma
}

// Se exporta con el mismo nombre de la funcion
export default getVconoce_derechos_idioma