<template>
    <div class="mainDiv">
        <Navbar />
        <ConoceTusDerechos />
        <NavbarBottom />
    </div>
            

</template>

<script>
import ConoceTusDerechos from '@/components/ConoceTusDerechos/ConoceTusDerechos'
import Navbar from '@/components/Common/Navbar'
import NavbarBottom from '@/components/Common/NavbarBottom'
export default {
  components: {
        ConoceTusDerechos,
        Navbar,
        NavbarBottom,
    },
    
    mounted() {
        window.scrollTo(0, 0)
    }
}
</script>
