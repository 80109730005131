/* Regresa todos los datos del usuario con el id enviado de la tabla 'usuarios' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getDataByAny = async( table, column, value ) => {
    const promiseArr = [
        simejuvApi.get(`/${ table }?transform=1&filter=${ column },eq,${ value }&borrar,eq,0`)
    ]

    const [ datos ] = await Promise.all( promiseArr )

    return datos.data.usuarios
}

export default getDataByAny