/* Regresa todos los datos del usuario con el id enviado de la tabla 'usuarios' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getAccesoByUser = async( id ) => {
    const promiseArr = [
        simejuvApi.get(`/usuarios/${ id }`)
    ]

    const [ user ] = await Promise.all( promiseArr )

    const idUser = user.data.id

    const promiseArrNew = [
        simejuvApi.get(`/acceso?transform=1&filter=id_usuarios,eq,${ idUser }`)
    ]

    const [ acc ] = await Promise.all( promiseArrNew )

    return acc.data.acceso
}

export default getAccesoByUser