/* Regresa todos los datos del usuario con el id enviado de la tabla 'usuarios' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getOnlyPss = async( id ) => {
    const promiseArr = [
        simejuvApi.get(`/acceso?transform=1&filter=id_usuarios,eq,${id}&columns=password`)
    ]

    const [ pss ] = await Promise.all( promiseArr )

    return pss.data.acceso
}

export default getOnlyPss