/* Regresa todos los datos del usuario con el id enviado de la tabla 'cat_conoce_derechos' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getMenuPaginaById = async( id ) => {
    const promiseArr = [
        simejuvApi.get(`/cat_menu_pagina/${ id }`)
    ]

    const [ menupagina ] = await Promise.all( promiseArr )

    return menupagina.data
}

export default getMenuPaginaById