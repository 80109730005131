<template>
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="/"><i class="glyphicon glyphicon glyphicon-home"></i></a></li>
        <li class="breadcrumb-item"><a href="/">CUIDANDO EL CAMINO</a></li>
        <li class="breadcrumb-item active" aria-current="page"><a href="#">Recuperación de contraseña</a></li>
    </ol>
    <h2>Recuperación de contraseña</h2>
    <hr class="red mb-5">
    <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6 col-sm-12">
            <div class="panel panel-success">
                <div class="panel-heading text-center">
                    <h3 class="panel-title">Recuperación de contraseña</h3>
                </div>
                <div class="panel-body">
                    <form role="form" @submit.prevent="recoverPass">
                        <div class="form-group row has-success has-feedback">
                            <label for="email">Correo electrónico registrado:</label>
                            <div class="col-sm-12">
                                <div class="input-group flex-nowrap">
                                    <span class="input-group-addon">@</span>
                                    <input type="text" id="email" v-model="email" class="form-control" placeholder="Ingresa tu correo electrónico" aria-label="Ingresa tu correo electrónico" aria-describedby="inputEmail">
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-12 text-right">
                                <input type="submit" class="btn btn-primary active btnCond" value="Solicitar" />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="col-md-3"></div>
    </div>
</template>

<script>
import getDataByAny from '@/helpers/getDataByAny'
import updateDataByUserId from '@/helpers/updateDataByUserId'
import md5 from 'js-md5'
import sendMailService from '@/services/SendMailService'

export default {
    data() {
        return {
            email: null,
            mensaje: ''
        }
    },
    methods: {
        async recoverPass() {
            const email = document.querySelector('#email').value;

            // Valido si el campo contiene datos
            if ( !email ) {
                this.$notify({
                    title: 'Error', text: 'El <strong>Correo electrónico</strong> no puede estar vacío, favor de verificarlo', type: 'error'
                })
                return
            }

            // Valido si el formato de correo es correcto
            const emailVal = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9\\-])+.)+([a-zA-Z0-9]{2,4})+$/
            if ( !emailVal.test( email ) ) {
                this.$notify({
                    title: 'Error', text: 'El <strong>Correo electrónico</strong> ingresado no es válido, favor de verificarlo', type: 'error'
                })
                return
            }

            // Verifico si existe el correo
            const valEmail = await getDataByAny( 'usuarios', 'email', email );
            if ( valEmail.length > 0 ){
                // Si existe
                const idUsr = valEmail[0].id
                // Genero un hash para colocarlo en la base de datos y usarlo como identificador
                const code = md5(idUsr + Date() + email)
                const jsonData = JSON.parse(JSON.stringify( { code: code } ))
                await updateDataByUserId('acceso', idUsr, jsonData)
                      .then( async resp => {
                          // Cuando se coloca el hash en la base hago el envío del correo correspondiente
                        //  if(resp){}    //emt julio marcaba error de q resp no se usa
                          await sendMailService.recover( code )
                                .then( r => { 
                                   r
                                })
                                .catch( e => { 
                                    console.log( 'Error de envío', e ); 
                                    return 
                                })
                      })
                      .catch( err => {
                          console.log( 'Error!!!', err );
                          return
                      })
            }

            this.$notify({
                title: 'Correcto', text: 'Si el correo electrónico indicado está registrado en el sistema recibirá en su bandeja el mensaje con las indicaciones para realizar el cambio de su contraseña. Gracias!', type: 'success', duration: 5000, speed: 1000
            });
            this.$router.push('/')
        }
    }
}
</script>

<style scoped>
    form {
        padding-top: 0;
    }

    .card {
        margin: 0 auto;
    }

    .card-header {
        background-color: #7F00FF;
        color: #FFF;
    }

    .card-body {
        background-color: #FFF;
    }

    .content {
        padding: 20px;
    }

    .note {
        width: 100%;
        font-size: small;
        text-align: right;
    }

    .input-group {
        display: flex;
    }

    .panel-center {
        margin: auto 0;
    }

    .panel-heading {
        background-color: #7F00FF;
        color: #FFF;
    }

    .panel-body {
        padding: 30px;
    }

    .input-group-addon {
        width: 40px;
    }
    
</style>