<template>
    <h4>Administración de usuarios</h4>
    <hr>
    <div class="row mb-3">
        <!-- Botón nuevo usuario -->
        <div class="col-md-4 text-left">
            <input type="button" class="btn btn-sm btn-secondary" value="Nuevo usuario" @click=" $router.push('/user/usrAdmin/add') ">
        </div>
        <!-- Input de búsqueda -->
        <div class="col-md-3 text-right"><label class="text-small">Buscar:</label></div>
        <div class="col-md-5 text-right"><input v-model="searchTerm" class="form-control busqueda" /></div>
    </div>
    <div class="row">
        <div class="col-md-12 datatable">
            <!-- Configuración de la tabla con los campos a mostrar -->
            <table-lite
                :is-static-mode="true"
                :columns="table.columns"
                :rows="table.rows"
                :pageSize=25
                :total="table.totalRecordCount"
                :sortable="table.sortable"
                :messages="table.messages"
            ></table-lite>
            
        </div>
    </div>
</template>

<script>
import { defineComponent, reactive, ref, computed } from "vue";
// Componente de la tabla
import TableLite from "@/components/DataTable/TableLite";

// Importo los helpers
import getAllUsers from '@/helpers/getAllUsers'
//import getDependenciasNc from '@/helpers/getDependenciasNc'

// Importo el servicio de codificación
import charsService from '@/services/CharsService';

export default defineComponent({
    components: { TableLite },
    setup() {
        const searchTerm = ref("") // Variable para los datos de búsqueda
        const data = reactive([]); // Variable para los datos a mostrar en la tabla
        //let arrDependencias = [];


        (async () => {
            //const consDeps = await getDependenciasNc( true );
            //for (let h = 0; h < consDeps.length; h++) {
            //    arrDependencias[consDeps[h].id] = consDeps[h].nom_corto_dependencia
            //}
            
            const usersArr = await getAllUsers()
            // Genero el arreglo de datos para llenar la tabla
            const datos = usersArr[0].usuarios
            for (let i = 0; i < datos.length; i++) {
                data.push({
                    id: datos[i].id,
                    nombre: datos[i].nombre + ' ' + datos[i].a_paterno,
                    //dependencia: arrDependencias[datos[i].id_dependencia],
                    email: datos[i].email,
                    tipo: datos[i].nom_tipo_usuario,
                });
            }
        })()
        
        // Configuración de la tabla
        const table = reactive({
            // Columnas de la tabla
            columns: [
                {
                    label: "Nombre",
                    field: "nombre",
                    width: "25%",
                    sortable: true,
                },
              /*  {
                    label: "Dependencia",
                    field: "dependencia",
                    width: "20%",
                    sortable: true,
                },  */
                {
                    label: "Correo/usuario",
                    field: "email",
                    width: "20%",
                    sortable: true,
                },
                {
                    label: "Nivel",
                    field: "tipo",
                    width: "20%",
                    sortable: true,
                },
                {
                    label: "",
                    field: "quick",
                    width: "15%",
                    display: function (row) {
                        // Iconos de acciones
                        const rowId = charsService.encode( row.id )
                        const tipoUsr = row.tipo

                        const btnAdv = (tipoUsr === 3)
                                       ? '<a href="/user/usrAdmin/advanced/'+ rowId +'" class="text-info" title="Ajustes avanzados"><i class="	glyphicon glyphicon-wrench"></i></a> ' + '&nbsp;&nbsp;&nbsp;'
                                       : ''

                        return (
                            '<a href="/user/usrAdmin/edit/'+ rowId +'" class="text-info" title="Editar"><i class="glyphicon glyphicon-pencil"></i></a> ' + '&nbsp;&nbsp;&nbsp;' + 
                            btnAdv +
                            '<a href="/user/usrAdmin/pswOth/'+ rowId +'" class="text-info" title="Cambiar password"><i class="glyphicon glyphicon-asterisk"></i></a> ' + '&nbsp;&nbsp;&nbsp;' + 
                            '<a href="#'+ rowId +'" class="text-info" title="Bloquear"><i class="glyphicon glyphicon-lock"></i></a> ' + '&nbsp;&nbsp;&nbsp;' + 
                            '<a href="/user/usrAdmin/remove/'+ rowId +'" class="text-info" title="Borrar"><i class="glyphicon glyphicon-trash"></i></a> ' + '&nbsp;&nbsp;&nbsp;' + 
                            ''
                        );
                    },
                },
            ],
            rows: computed(() => {
                // Aquí se designan las columnas en las que se realizará la búsqueda desde el input
                return data.filter(
                    (x) =>
                        x.nombre.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
                        //x.dependencia.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
                        x.email.toLowerCase().includes(searchTerm.value.toLowerCase())
                    );
            }),
            totalRecordCount: computed(() => {
                // Se regresa el número de registros encontrados
                return table.rows.length
            }),
            sortable: {
                // Se define el orden por default
                order: "id",
                sort: "asc",
            },
            messages: {
                // Personalizo los mensajes que muestra la tabla
                pagingInfo: "Mostrando {0}-{1} de {2}",
                pageSizeChangeLabel: "Filas:",
                gotoPageLabel: "   Ir a la página:",
                noDataAvailable: "Sin datos para mostrar",
            },
        });

        return {
            searchTerm,
            table,
        }
    }
})
</script>

<style scoped>
    .card :deep .table .thead-dark th {
        color: #000;
        background-color: #fff;
        border-color: #fff;
    }
    .card :deep .table td, .card :deep .table tr{
        border: none;
        width: 800px;
    }
    .text-small {
        font-size: medium;
    }
    .busqueda {
        font-size: medium;
        height: auto;
    }
    .text-info {
        color: #343a40 !important;
        padding: 0 20px;
    }
    .datatable {
        padding-bottom: 10px;
        overflow-x: auto;
        margin-bottom: 25px;
    }
    .card {
        border: 0 !important;
    }
</style>