<template>
    <h4 class="mt-0">Actualizar textos e imagenes del módulo Conoce tus derechos</h4>
    <hr>
    <div class="row mb-5">
        <div class="col-md-12">
            <form>
                <div class="form-group row">
                    <label class="col-lg-4 col-md-6 col-sm-12 col-form-label" for="ididioma">Idioma:</label>
                    <div class="col-lg-4 col-md-6 col-sm-12">
                        <select class="form-control" id="ididioma" name="ididioma" v-model="form.ididioma">
                            <option v-for="e_d in idiomasArr" :key="e_d.id" :value="e_d.id">{{ e_d.nom_corto_idioma }} {{ e_d.nom_idioma }}</option>
                        </select>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4" to="contenido_principal">Texto del contenido:</label>
                    <div class="col-md-8">
                        <input type="hidden" id="idBienvenida" name="idBienvenida" v-model="form.id" />
                        <ckeditor :editor="editor" v-model="form.contenido_principal" :config="editorConfig" id="contenido_principal" @input="conteo_real(form.contenido_principal, 'contenido_principal')"></ckeditor>
                        <div id="contenido_principal_count" class="noChars"></div>
                    </div>
                </div>

                <!--------------------- seccion para la imagen ---------------------------------->
                <div class="form-group row">
                    <label hidden class="col-md-4" to="imagen_url">Archivo en servidor:</label>
                    <div class="col-md-8">
                        <input type="hidden" class="form-control" id="imagen_url" name="imagen_url" placeholder="Nombre en el server" v-model="form.imagen_url" />
                    </div>
                </div>
                <div class="form-group row">
                    <label hidden class="col-md-4" to="imagen_nombre">Nombre del archivo:</label>
                    <div class="col-md-8">
                        <input type="hidden" class="form-control" id="imagen_nombre" name="imagen_nombre" placeholder="nombre del archivo" v-model="form.imagen_nombre" />
                    </div>
                </div>
                <div class="form-group row">
                    <label hidden class="col-md-4" to="imagen_extension">Extensión del archivo:</label>
                    <div class="col-md-8">
                        <input type="hidden" class="form-control" id="imagen_extension" name="imagen_extension" placeholder="extensión" v-model="form.imagen_extension" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-5 col-form-label" for="archivo">Subir la imagen:</label>
                    <div class="col-md-7">
                        <div v-if="successAlertImg" class="alert alert-success alert-dismissible">
                            <a href="#" class="close" aria-label="close" @click="successAlertImg=false">&times;</a>
                            {{ successMessageImg }}
                        </div>
                        <div v-if="errorAlertImg" class="alert alert-danger alert-dismissible">
                            <a href="#" class="close" aria-label="close" @click="errorAlertImg=false">&times;</a>
                            {{ errorMessageImg }}
                        </div>
                        <input type="file" ref="imagen" />
                        <br>
                        <button type="button" @click="uploadImagen" class="btn btn-default btn-sm">Subir imagen</button>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-5"></div>
                    <div class="col-md-7">
                        <table id="tblFiles" class="table table-striped">
                            <thead class="table-striped">
                                <tr>
                                    <th>Nombre de la imagen</th>
                                    
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><a :href="`${ ubApi }/download.php?tmpFileName=${ form.imagen_url }&fileName=${ form.imagen_nombre }`" target="_blank" title="Descargar"> {{ form.imagen_nombre_dec }}</a></td>
                                   
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>




                <div class="form-group row">
                    <div class="col-md-6 text-left">
                        <input type="button" class="btn btn-default btn-sm active" id="regresar" name="regresar" value="Regresar" @click="goBack" />
                    </div>
                    <div class="col-md-6 text-right">
                        <input type="button" class="btn btn-primary btn-sm active" id="guardar" name="guardar" value="Guardar" @click="updateBienvenida" />
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import getConoceDerechosById from '@/helpers/getConoceDerechosById'
import updateData from '@/helpers/updateData'
import getCatsNc from '@/helpers/getCatsNc'

import axios from 'axios'
import { ubApi } from '@/api/baseUrl'
import charsService from '@/services/CharsService'

//import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
//import '@ckeditor/ckeditor5-build-classic/build/translations/es'

import { ClassicEditor, Bold, Essentials, Italic, Mention, Paragraph, Undo, Font, FontColor, Heading, FontFamily, Indent, IndentBlock, BlockQuote, Link, List} from 'ckeditor5';
/* La opción List habilita:  'bulletedList', 'numberedList'  */
import 'ckeditor5/ckeditor5.css';




export default {
    data() {
        return {
            editor: ClassicEditor,
            editorData: '',
            editorConfig: {
              language: 'es',
              wordCount: {
                    showParagraphs: false,
                    showWordCount: true,
                    showCharCount: true,
                    countSpacesAsChars: false,
                    countHTML: false,
                    maxWordCount: -1,
                    maxCharCount: 4000
              },  
              plugins: [ Bold, Essentials, Italic, Mention, Paragraph, Undo, Font, FontColor, Heading, FontFamily, Indent, IndentBlock, BlockQuote, Link, List ],
   //           toolbar: [ 'heading', 'undo', 'redo', 'bulletedList', 'numberedList', 'fontColor', 'backgroundColor', 'bold', 'italic','fontSize', 'fontFamily'],
              fontFamily: {
                  options: [
                      'default',
                      'Helvetica',
                      'Arial',
                      'sans-serif',
                      'Courier New',
                      'Courier',
                      'monospace',
                      'Tahoma',
                      'Verdana'
                  ]
              },
              heading: {
                  options: [
                      { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                      { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                      { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                      { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                      { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                      { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                      { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' }
                  ]
              },
              fontColor: {
                  colorPicker: {
                      // Use 'hex' format for output instead of 'hsl'.
                      format: 'hex',
                      color: ['#30156B', '#542EA5','#7B62D9','#B995FF','#AD00E8','#78787A','#0D0D0D']
                  }
              },
              toolbar: {
                items: [
                  'heading',
                  '|', 'undo', 'redo',
                  '|', 'bold', 'italic',
                  '|', 'fontFamily', 'fontColor', 'backgroundColor', 'fontSize', 
                  '|', 'link', 'uploadImage', 'insertTable', 'blockQuote', 'mediaEmbed',
                  '|', 'bulletedList', 'numberedList', 'outdent', 'indent'
                  

                ]
              },
            },
            ubApi: ubApi.value,
            id: '',
            data: [],
            datos: [],

            imagen: '',
            successAlertImg: false,
            errorMessageImg: '',
            successMessageImg: '',
            errorAlertImg: false,

            form: {
                id: '',
                ididioma: '',
                contenido_principal: '',
                imagen_url: '',
                imagen_url_dec: '',
                imagen_nombre: '',
                imagen_nombre_dec: '',
                imagen_extension: '',
            },
            idiomasArr: [],
        }
    },
    methods: {
        async setData( id ) {
            const datos = await getConoceDerechosById( id )
            //alert (datos.contenido_principal)
            this.form.id = datos.id
            this.form.ididioma = datos.ididioma
            this.form.contenido_principal = datos.contenido_principal
            this.form.imagen_url = charsService.encode(datos.imagen_url)
            this.form.imagen_url_dec = datos.imagen_url
            this.form.imagen_nombre = charsService.encode(datos.imagen_nombre)
            this.form.imagen_nombre_dec = datos.imagen_nombre
            this.form.imagen_extension = datos.imagen_extension

        },

        async updateBienvenida() {
            const dataForm = {
                ididioma: this.form.ididioma,
                contenido_principal: this.form.contenido_principal,
                imagen_url: this.form.imagen_url_dec,
                imagen_nombre: this.form.imagen_nombre_dec,
                imagen_extension: this.form.imagen_extension,
            };

            const jsonData = JSON.parse(JSON.stringify( dataForm ));

            const updAcc = await updateData('cat_conoce_derechos', this.form.id, jsonData)

            if ( updAcc[0].res == 1 ){
                this.$notify({
                    title: 'Correcto', text: 'Se actualizó correctamente la información', type: 'success', duration: 5000, speed: 1000
                });
            } else {
                this.$notify({
                    title: 'Error', text: 'Ocurrió un error. Verifica los datos', type: 'error'
                });
            }
        },

        async uploadImagen() {
            this.imagen = this.$refs.imagen.files[0]
            var formDataImg = new FormData()
            formDataImg.append('file', this.imagen)

            let z_errorAlert = false
            let z_successAlert = false
            let z_errorMessage = ''
            let z_successMessage = ''
            let z_userId = ''
            let z_fileValue = ''
            let z_tmpFileName = ''
            let z_baseName = ''
            let z_extension = ''
            
            // await axios.post('http://localhost/ubApi/uploadFiles.php', formDataImg, {
            await axios.post( ubApi.value + '/uploadFiles.php', formDataImg, {
                header:{
                    'Content-Type' : 'multipart/form-data'
                }
            }).then(function(response){
                if(response.data.file == '') {
                    z_errorAlert = true
                    z_successAlert = false
                    z_errorMessage = response.data.message
                    z_successMessage = ''
                    z_userId = ''
                    z_tmpFileName = ''
                    z_baseName = ''
                    z_extension = ''
                } else {    /* Se asignan los datos para el update*/
                    z_errorAlert = false
                    z_successAlert = true
                    z_errorMessage = ''
                    z_successMessage = response.data.message
                    z_userId = charsService.decode(localStorage.getItem('id'))
                    z_tmpFileName = response.data.tmpFile
                    z_baseName = response.data.baseName
                    z_extension = response.data.extension
                    z_fileValue = ''
                    //alert ("entro a asignar archuvos:" + z_tmpFileName)
                }
            });

            this.errorAlertImg = z_errorAlert
            this.successAlertImg = z_successAlert
            this.errorMessageImg = z_errorMessage
            this.successMessageImg = z_successMessage
            this.$refs.imagen.value = z_fileValue

            if (z_successAlert) {
                this.form.id_usuario=z_userId
                this.form.imagen_url=z_tmpFileName
                this.form.imagen_url_dec=z_tmpFileName
                this.form.imagen_nombre=z_baseName
                this.form.imagen_nombre_dec=z_baseName
                this.form.imagen_extension=z_extension
            }
        },
        async getIdiomas() {
            const cats = await getCatsNc('/cat_idioma?transform=1&columns=id,nom_corto_idioma,nom_idioma&filter=borrar,lt,1')
            return cats.cat_idioma

        },
        goBack() {
            this.$router.go(-1)
        },

        decodeHTMLEntities(text) {
            var textArea = document.createElement('textarea');
            textArea.innerHTML = text;
            return textArea.value;
        },

        async conteo_real( cont, id ) {
            const div = document.createElement('div');
            div.innerHTML = cont;
            const chars = div.textContent || div.innerText || '';
            const charsCount = this.decodeHTMLEntities(chars).length
            document.querySelector("#"+ id +"_count").innerHTML = "Número de caracteres: " + charsCount
        }

    },
    async mounted() {
        //alert(charsService.decode( this.$route.params.id ))
        this.setData( charsService.decode( this.$route.params.id ))
        this.idiomasArr = await this.getIdiomas()
        window.scrollTo(0, 0)
    }
}
</script>

<style scoped>
    form {
        font-size: medium;
    }

    input, select {
        font-size: medium;
    }

    input[type=button] {
        font-size: 14px;
    }

    #bt1_desc {
        height: 500px;
    }

    .noChars {
        width: 100%;
        text-align: right;
        background-color: #FAFAFA;
        border: 1px solid #C4C4C4;
        border-top: none;
        color: #000;
        font-weight: 500;
        padding: 3px 10px;
    }
</style>