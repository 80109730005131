<template>
  <router-view/>
  <notifications position="top right" width="100%" />
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  /*-webkit-font-smoothing: antialiased; */
  /*-moz-osx-font-smoothing: grayscale; */
  /*text-align: center; */
  /*color: #542EA5; */
  /*margin-top: 60px; */
}
.mb-4 {
    margin-bottom: 15px;
}

/* smartphones, portrait iPhone, portrait 480x320 phones (Android) */

@media (min-width:320px)  { 
  .h1,
    h1 {
      font-size: 2.0em;
    }
}
@media (min-width:1025px) { 
  .h1,
    h1 {
      font-size: 3.0em;
    }
}             

</style>
