/* Regresa todas las tipos de usuario de la tabla 'cat_tipos_usuario' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getTiposUsuario = async() => {
    const promiseArr = [
        simejuvApi.get(`/cat_tipos_usuario?transform=1&filter=borrar,lt,1`)
    ]

    const [ tipos ] = await Promise.all( promiseArr )

    return tipos.data.cat_tipos_usuario
}

export default getTiposUsuario