<template>
    <br><br>


    <div class="row">
            <!--<div class="col-lg-12 col-md-8 col-sm-6" style="width: 100%;">-->
            <div class="col-lg-12" style="width: 100%;">
                <div class="article-body" >
                    <p class="card-text" v-html="contenido"></p>
                </div>
            </div>
            <div class="col-lg-12" style="width: 100%;">
                <div class="article-body" >
                    <div style="padding: 5px 20px 5px 15px; box-shadow: 5px 5px 15px gray;">
                        <iframe class="iframe-config" :src="url_mapa" width="100%" frameborder="0" marginheight="0" allow="fullscreen"></iframe>
                    </div> 
                </div>
            </div>
            
            <div class="col-lg-12" style="width: 100%; padding-top: 40px;">
                <div class="article-body" >
                    <div style="padding: 5px 20px 5px 15px; box-shadow: 5px 5px 15px gray;">
                        <iframe class="iframe-config" :src="url_tabla" width="100%" frameborder="0" marginheight="0" allow="fullscreen"></iframe>
                    </div> 
                </div>
            </div>
            <br>
            <div class="col-lg-12" style="width: 100%;">
                <div class="article-body" >
                    <p class="card-text" v-html="texto_inferior"></p>
                </div>
            </div>
    </div>
    <br>
    <br>
    <br>

</template>

<script>
//import axios from 'axios'
import getCatServicioRespById from '@/helpers/getCatServicioRespById'

export default {
  components: {

  },
  data() {
      return {
            contenido:'',
            url_mapa:'',
            url_tabla:'',
            texto_inferior:'',

      }
  },
  methods: {
      async getCat_Servicioresp() {
            const datos = await getCatServicioRespById(  localStorage.getItem("Nidioma") )
            this.contenido = datos.contenido
            this.url_mapa = datos.url_mapa
            this.url_tabla = datos.url_tabla
            this.texto_inferior = datos.texto_inferior
      },
  },
  async mounted() {
        await this.getCat_Servicioresp();
        //this.$gtag.event('Directorio');     //Registra el Evento en Google Analytics con nombre
  }
}
</script>


<style scoped>

/* APARTADO RESPONSIVO */
          @media (min-width:320px)  { 
            /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
                .iframe-config{
                    height: 550px;
                }
           }
          @media (min-width:480px)  { 
          /* smartphones, Android phones, landscape iPhone */ 
                .iframe-config{
                    height: 500px;
                }
          }
          @media (min-width:600px)  { 
          /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */ 
                .iframe-config{
                    height: 500px;
                }
          }
          @media (min-width:801px)  { 
          /* tablet, landscape iPad, lo-res laptops ands desktops */ 
                .iframe-config{
                    height: 500px;
                }
          }
          @media (min-width:1025px) { 
          /* big landscape tablets, laptops, and desktops */ 
                .iframe-config{
                    height: 500px;
                }
          }
          @media (min-width:1281px) { 
          /* hi-res laptops and desktops */
                .iframe-config{
                    height: 800px;
                }
          } 
</style>