<template>
    <h4 class="mt-0">Actualizar textos del módulo Directorio de servicios</h4>
    <hr>
    <div class="row mb-5">
        <div class="col-md-12">
            <form>
                <div class="form-group row">
                    <label class="col-lg-4 col-md-6 col-sm-12 col-form-label" for="ididioma">Idioma:</label>
                    <div class="col-lg-4 col-md-6 col-sm-12">
                        <select class="form-control" id="ididioma" name="ididioma" v-model="form.ididioma">
                            <option v-for="e_d in idiomasArr" :key="e_d.id" :value="e_d.id">{{ e_d.nom_corto_idioma }} {{ e_d.nom_idioma }}</option>
                        </select>
                    </div>
                </div>


                <div class="form-group row">
                    <label class="col-md-4" to="btn_inicio_desc">Contenido del botón inicio:</label>
                    <div class="col-md-8">
                        <ckeditor :editor="editor" v-model="form.btn_inicio_desc" :config="editorConfig" id="btn_inicio_desc" @input="conteo_real(form.btn_inicio_desc, 'btn_inicio_desc')"></ckeditor>
                        <div id="btn_inicio_desc_count" class="noChars"></div>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-md-4" to="btn_acerca_desc">Contenido del botón acerca:</label>
                    <div class="col-md-8">
                        <ckeditor :editor="editor" v-model="form.btn_acerca_desc" :config="editorConfig" id="btn_acerca_desc" @input="conteo_real(form.btn_acerca_desc, 'btn_acerca_desc')"></ckeditor>
                        <div id="btn_acerca_desc_count" class="noChars"></div>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-md-4" to="btn_directorio_desc">Contenido del botón directorio:</label>
                    <div class="col-md-8">
                        <ckeditor :editor="editor" v-model="form.btn_directorio_desc" :config="editorConfig" id="btn_directorio_desc" @input="conteo_real(form.btn_directorio_desc, 'btn_directorio_desc')"></ckeditor>
                        <div id="btn_directorio_desc_count" class="noChars"></div>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-md-4" to="btn_informacion_desc">Contenido del botón información:</label>
                    <div class="col-md-8">
                        <ckeditor :editor="editor" v-model="form.btn_informacion_desc" :config="editorConfig" id="btn_informacion_desc" @input="conteo_real(form.btn_informacion_desc, 'btn_informacion_desc')"></ckeditor>
                        <div id="btn_informacion_desc_count" class="noChars"></div>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-md-4" to="btn_registro_desc">Contenido del botón registro:</label>
                    <div class="col-md-8">
                        <ckeditor :editor="editor" v-model="form.btn_registro_desc" :config="editorConfig" id="btn_registro_desc" @input="conteo_real(form.btn_registro_desc, 'btn_registro_desc')"></ckeditor>
                        <div id="btn_registro_desc_count" class="noChars"></div>
                    </div>
                </div>


                <div class="form-group row">
                    <div class="col-md-6 text-left">
                        <input type="button" class="btn btn-default btn-sm active" id="regresar" name="regresar" value="Regresar" @click="goBack" />
                    </div>
                    <div class="col-md-6 text-right">
                        <input type="button" class="btn btn-primary btn-sm active" id="guardar" name="guardar" value="Guardar" @click="updateBienvenida" />
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import getMenuPaginaById from '@/helpers/getMenuPaginaById'
import updateData from '@/helpers/updateData'
import getCatsNc from '@/helpers/getCatsNc'

import charsService from '@/services/CharsService'
//import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
//import '@ckeditor/ckeditor5-build-classic/build/translations/es'
//import FontColor from '@ckeditor/ckeditor5-build-classic'

import { ClassicEditor, Bold, Essentials, Italic, Mention, Paragraph, Undo, Font, FontColor, Heading, FontFamily, Indent, IndentBlock, BlockQuote, Link, List} from 'ckeditor5';
import 'ckeditor5/ckeditor5.css';

export default {
    data() {
        return {
            editor: ClassicEditor,
            editorData: '',
            editorConfig: {
                language: 'es',
                wordCount: {
                    showParagraphs: false,
                    showWordCount: true,
                    showCharCount: true,
                    countSpacesAsChars: false,
                    countHTML: false,
                    maxWordCount: -1,
                    maxCharCount: 4000
                },
              plugins: [ Bold, Essentials, Italic, Mention, Paragraph, Undo, Font, FontColor, Heading, FontFamily, Indent, IndentBlock, BlockQuote, Link, List ],
              //plugins: [FontColor],
              fontFamily: {
                  options: [
                      'default',
                      'Helvetica',
                      'Arial',
                      'sans-serif',
                      'Courier New',
                      'Courier',
                      'monospace',
                      'Tahoma',
                      'Verdana'
                  ]
              },
              heading: {
                  options: [
                      { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                      { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                      { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                      { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                      { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                      { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                      { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' }
                  ]
              },
              fontColor: {
                  colorPicker: {
                      // Use 'hex' format for output instead of 'hsl'.
                      format: 'hex',
                      color: ['#30156B', '#542EA5','#7B62D9','#B995FF','#AD00E8','#78787A','#0D0D0D']
                  }
              },
              toolbar: {
                items: [
                  'heading',
                  '|', 'undo', 'redo',
                  '|', 'bold', 'italic',
                  '|', 'fontFamily', 'fontColor', 'backgroundColor', 'fontSize', 
                  '|', 'link', 'uploadImage', 'insertTable', 'blockQuote', 'mediaEmbed',
                  '|', 'bulletedList', 'numberedList', 'outdent', 'indent'
                  

                ]
              },
            },




            id: '',
            data: [],
            datos: [],
            form: {
                id: '',
                ididioma: '',
                btn_inicio_desc: '',
                btn_acerca_desc: '',
                btn_directorio_desc: '',
                btn_informacion_desc: '',
                btn_registro_desc: '',
            },
            idiomasArr: [],
        }
    },
    methods: {


        async setData( id ) {
            const datos = await getMenuPaginaById( id )

            this.form.id = datos.id
            this.form.ididioma = datos.ididioma
            this.form.btn_inicio_desc = datos.btn_inicio_desc
            this.form.btn_acerca_desc = datos.btn_acerca_desc
            this.form.btn_directorio_desc = datos.btn_directorio_desc            
            this.form.btn_informacion_desc = datos.btn_informacion_desc
            this.form.btn_registro_desc = datos.btn_registro_desc
        },

        async updateBienvenida() {
            const dataForm = {
                ididioma: this.form.ididioma,
                btn_inicio_desc: this.form.btn_inicio_desc,
                btn_acerca_desc: this.form.btn_acerca_desc,
                btn_directorio_desc: this.form.btn_directorio_desc,
                btn_informacion_desc: this.form.btn_informacion_desc,
                btn_registro_desc: this.form.btn_registro_desc,
            };

            const jsonData = JSON.parse(JSON.stringify( dataForm ));

            const updAcc = await updateData('cat_menu_pagina', this.form.id, jsonData)

            if ( updAcc[0].res == 1 ){
                this.$notify({
                    title: 'Correcto', text: 'Se actualizó correctamente la información', type: 'success', duration: 5000, speed: 1000
                });
            } else {
                this.$notify({
                    title: 'Error', text: 'Ocurrió un error. Verifica los datos', type: 'error'
                });
            }
        },
        async getIdiomas() {
            const cats = await getCatsNc('/cat_idioma?transform=1&columns=id,nom_corto_idioma,nom_idioma&filter=borrar,lt,1')
            return cats.cat_idioma

        },
        goBack() {
            this.$router.go(-1)
        },

        decodeHTMLEntities(text) {
            var textArea = document.createElement('textarea');
            textArea.innerHTML = text;
            return textArea.value;
        },

        async conteo_real( cont, id ) {
            const div = document.createElement('div');
            div.innerHTML = cont;
            const chars = div.textContent || div.innerText || '';
            const charsCount = this.decodeHTMLEntities(chars).length
            document.querySelector("#"+ id +"_count").innerHTML = "Número de caracteres: " + charsCount
        }

    },
    async mounted() {
        this.setData( charsService.decode( this.$route.params.id ))
        this.idiomasArr = await this.getIdiomas()
        window.scrollTo(0, 0)
    }
}
</script>

<style scoped>
    form {
        font-size: medium;
    }

    input, select {
        font-size: medium;
    }

    input[type=button] {
        font-size: 14px;
    }

    #texto_inferior {
        height: 500px;
    }

    .noChars {
        width: 100%;
        text-align: right;
        background-color: #FAFAFA;
        border: 1px solid #C4C4C4;
        border-top: none;
        color: #000;
        font-weight: 500;
        padding: 3px 10px;
    }
</style>