<template>
    <h4>Cambiar contraseña</h4>
    <hr>
    <div class="row mb-5">
        <div class="col-md-12">
            <div>
                Se recomienda usar una contraseña segura que no uses en ningún otro sitio y debe estar conformada por lo menos con:
                <ul>
                    <li>8 caracteres</li>
                    <li>Una mayúscula</li>
                    <li>Una minúscula</li>
                    <li>Un número</li>
                    <li>Un caracter especial</li>
                </ul>
            </div>
            <form role="form" @submit.prevent="changePass">
                <div class="form-group">
                    <label class="control-label" for="new">Nueva contraseña:</label>
                    <div class="col-md-12">
                        <input class="form-control" id="new" placeholder="Nueva contraseña" type="password">
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label" for="renew">Confirmar nueva contraseña:</label>
                    <div class="col-md-12">
                        <input class="form-control" id="renew" placeholder="Confirmar nueva contraseña" type="password">
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-md-6 text-left">
                        <button class="btn btn-default btn-sm active btnCond" type="button" @click="goBack">Regresar</button>
                    </div>
                    <div class="col-md-6 text-right">
                        <input class="btn btn-primary btn-sm active btnCond" type="submit" value="Guardar" />
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
//import getOnlyPss from '@/helpers/getOnlyPss'
import addData from '@/helpers/addData'
import updateDataByUserId from '@/helpers/updateDataByUserId'
import charsService from '@/services/CharsService';
import sha1 from 'sha1';
import getUsersById from '@/helpers/getUsersById';
import sendMailService from '@/services/SendMailService'

export default {
    methods: {
        goBack() {
            this.$router.go(-1)
        },
        async changePass() {
            const pssNew = document.querySelector("#new").value
            const pssRe = document.querySelector("#renew").value
            const specialChars = /[(@!#$%^&*)(+=._-]{1,}/

            const userData = await getUsersById( charsService.decode(this.$route.params.id) );
            const usrNombre = userData['nombre'];
            const usrApaterno = userData['a_paterno'];
            const usrEmail = userData['email'];

            if ( !pssNew ) {
                this.$notify({
                    title: 'Error!', text: `La contraseña nueva no puede estar vacía`, type: 'error', duration: 5000, speed: 1000
                })
            } else if ( pssNew.length < 8 ) {
                this.$notify({
                    title: 'Error!', text: 'La contraseña debe contener por lo menos 8 caracteres', type: 'error', duration: 5000, speed: 1000
                })
            } else if ( !/[a-z]/.test(pssNew) || !/[A-Z]/.test(pssNew) || !/[0-9]/.test(pssNew) || !specialChars.test(pssNew) ) {
                this.$notify({
                    title: 'Error!', text: 'La contraseña debe contener por lo menos una mayúscula, una minúscula, un número y un caracter especial', type: 'error', duration: 5000, speed: 1000
                })
            } else if ( pssNew !== pssRe ) {
                this.$notify({
                    title: 'Error!', text: 'La contraseña nueva y su confirmación no coinciden', type: 'error', duration: 5000, speed: 1000
                })
            } else {
                const arrUpd = [{ password: sha1(pssNew) }]
                const jsonUpd = JSON.parse(JSON.stringify( arrUpd ))
                const response = await updateDataByUserId('acceso', charsService.decode(this.$route.params.id), jsonUpd)
                const dataForm = {
                    usr: charsService.decode(localStorage.getItem('id')),
                    cambio: 'Cambió password de otro usuario ->' + 'idUsr: ' + charsService.decode(this.$route.params.id) + ' -- password: ' + sha1(pssNew)
                }
                const jsonData = JSON.parse(JSON.stringify( dataForm ))
//                const updLog = await addData('logPass', jsonData)         //EMT julio
                await addData('logPass', jsonData)

                if (response.data === 1 && response.status === 200) {
                    this.$notify({
                        title: 'Correcto.', text: `La información ha sido actualizada`, type: 'success', duration: 5000, speed: 1000
                    })
                    console.log(usrNombre);
                    console.log(usrApaterno);
                    console.log(usrEmail);
                    console.log(pssNew);
//                    const envio = await sendMailService.newUser( usrNombre, usrApaterno, usrEmail, pssNew );   //Emt julio
                    await sendMailService.newUser( usrNombre, usrApaterno, usrEmail, pssNew );
                    this.$router.go(-1)
                } else {
                    this.$notify({
                        title: 'Error!', text: `Hubo un error al guardar la contraseña, vuelve a intentarlo. Error no: ${ response.status }. Error: ${ response.statusText }`, type: 'error', duration: 5000, speed: 1000
                    })
                }
                // updateData()
            }

            

            return false

        }
    }
}
</script>

<style scoped>
    form {
        padding-top: 20px;
        font-size: medium;
    }

    input {
        font-size: medium;
        margin-bottom: 20px;
    }

    @media screen and (max-width: 768px) {
        .btnCond {
            display: block;
            width: 100%;
        }
    }

    ul {
        padding-top: 20px;
        font-size: large;
        font-weight: bold;
    }
</style>