<template>
    <div class="mainDiv">
        <Navbar />
        <AcercadeDesc />
        <NavbarBottom />
    </div>
</template>

<script>
import AcercadeDesc from '@/components/Acercade/AcercadeDesc'
import Navbar from '@/components/Common/Navbar'
import NavbarBottom from '@/components/Common/NavbarBottom'


export default {
  components: {
    Navbar,
    NavbarBottom,
    AcercadeDesc,
  },
  mounted() {
    window.scrollTo(0, 0)
  }
}
</script>