/* Regresa todos los datos del usuario con el id enviado de la tabla 'usuarios' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getAccesoByCode = async( code ) => {
    const promiseArr = [
        simejuvApi.get(`/acceso?transform=1&filter=code,eq,${ code }`)
    ]

    const [ acc ] = await Promise.all( promiseArr )

    return acc.data.acceso
}

export default getAccesoByCode