/* Regresa todos los datos del usuario con el id enviado de la tabla 'cat_conoce_derechos' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getConoceDerechosById = async( id ) => {
    const promiseArr = [
        simejuvApi.get(`/cat_conoce_derechos/${ id }`)
    ]

    const [ cat_conoce_derechos ] = await Promise.all( promiseArr )

    return cat_conoce_derechos.data
}

export default getConoceDerechosById