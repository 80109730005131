import baseApi from '../api/baseApi'
import charsService from '@/services/CharsService'
import getAccesoByCode from '@/helpers/getAccesoByCode'
import getUsersById from '@/helpers/getUsersById'

class SendMailService {
    async newUser( nombre, aPaterno, email, pass ) {
        const nombreEnc = charsService.encode(nombre)
        const aPaternoEnc = charsService.encode(aPaterno)
        const emailEnc = charsService.encode(email)
        const passEnc = charsService.encode(pass)
        const typeEnc = charsService.encode('newUser')

        const promiseArr = [
            baseApi.post(`/sendMail.php?nombre=${nombreEnc}&aPaterno=${aPaternoEnc}&email=${ emailEnc }&pass=${ passEnc }&type=${ typeEnc }`)
        ]
    
        const [ mailer ] = await Promise.all( promiseArr )
        alert("se envio info" + emailEnc)
        return mailer
    }

    async recover( hash ) {
        const acceso = await getAccesoByCode( hash )
        const usrId = acceso[0].id_usuarios
        const usuario = await getUsersById( usrId )
        const typeEnc = charsService.encode('recover')

        const nombreEnc = charsService.encode(usuario.nombre)
        const aPaternoEnc = charsService.encode(usuario.a_paterno)
        const emailEnc = charsService.encode(usuario.email)

        const promiseArr = [
            baseApi.post(`/sendMail.php?nombre=${nombreEnc}&aPaterno=${aPaternoEnc}&email=${ emailEnc }&hash=${ hash }&type=${ typeEnc }`)
        ]
        
        const [ mailer ] = await Promise.all( promiseArr )
    
        return mailer
    }

    async reportSave( nombre, aPaterno, email, accion ) {
        const nombreEnc = charsService.encode(nombre)
        const aPaternoEnc = charsService.encode(aPaterno)
        const emailEnc = charsService.encode(email)
        const accionEnc = charsService.encode(accion)
        const typeEnc = charsService.encode('reportSave')

        const promiseArr = [
            baseApi.post(`/sendMail.php?nombre=${nombreEnc}&aPaterno=${aPaternoEnc}&email=${ emailEnc }&accion=${ accionEnc }&type=${ typeEnc }`)
        ]
    
        const [ mailer ] = await Promise.all( promiseArr )
    
        return mailer
    }

    async addIntervencion( nombre, aPaterno, email, intervencion ) {
        const nombreEnc = charsService.encode(nombre)
        const aPaternoEnc = charsService.encode(aPaterno)
        const emailEnc = charsService.encode(email)
        const intervencionEnc = charsService.encode(intervencion)
        const typeEnc = charsService.encode('addIntervencion')

        const promiseArr = [
            baseApi.post(`/sendMail.php?nombre=${nombreEnc}&aPaterno=${aPaternoEnc}&email=${ emailEnc }&intervencion=${ intervencionEnc }&type=${ typeEnc }`)
        ]
    
        const [ mailer ] = await Promise.all( promiseArr )
    
        return mailer
    }

    async addCalc( nombre, aPaterno, email, calculo ) {
        const nombreEnc = charsService.encode(nombre)
        const aPaternoEnc = charsService.encode(aPaterno)
        const emailEnc = charsService.encode(email)
        const calculoEnc = charsService.encode(calculo)
        const typeEnc = charsService.encode('addCalc')

        const promiseArr = [
            baseApi.post(`/sendMail.php?nombre=${nombreEnc}&aPaterno=${aPaternoEnc}&email=${ emailEnc }&calculo=${ calculoEnc }&type=${ typeEnc }`)
        ]
    
        const [ mailer ] = await Promise.all( promiseArr )
    
        return mailer
    }
}

export default new SendMailService()