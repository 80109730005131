import axios from 'axios'

const baseApi = axios.create({
    //baseURL: 'http://localhost/ubApi',
    //baseURL: 'https://caminos-segurosv2.rmsh.club/ubApi',
    baseURL: 'https://cc.rmsh.club/ubApi',


    headers: {
        "Content-type": "application/json"
    }
})

export default baseApi