/* Inserta un registro en la tabla pasada por parámetro en la base de datos */

import simejuvApi from '../api/simejuvApi'

const insertData = async( table, data ) => {
    const promiseArr = [
        simejuvApi.post(`/${ table }`, data)
    ]

    const [ add ] = await Promise.all( promiseArr )

    return [
        { res: add }
    ]
}

export default insertData