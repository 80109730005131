/* Regresa todos los datos del usuario con el id enviado de la tabla 'usuarios' en la base de datos */

import simejuvApi from '../api/simejuvApi'

const getAcceso = async( email ) => {
    const promiseArr = [
        simejuvApi.get(`/v_acceso?transform=1&filter[]=borrar,lt,1&filter[]=email,eq,${ email }`)
    ]

    const [ user ] = await Promise.all( promiseArr )

    return user.data.v_acceso
}

export default getAcceso