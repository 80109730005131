<template>
    <div class="mainDiv">
        Saliendo...
    </div>
</template>

<script>
export default {
  mounted() {
    localStorage.removeItem('nombre')
    localStorage.removeItem('tipo_usuario')
    localStorage.removeItem('email')
    localStorage.removeItem('dependencia')
    localStorage.removeItem('id')
    localStorage.removeItem('token')
    this.$router.push('/')
  }
}
</script>